import React, { useState } from "react";
import { useField, useCheckbox } from "../../hooks";
import formService from "./../../services/form";
import {
  Flex,
  Col,
  Contain,
  Input,
  TextArea,
  Button,
} from "../../components/styledComponent/index";
import data from "./recruiterFormData.json";
import "./Recruiter.css";

function RecruiterForm({ setSubmitted }) {
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const name = useField("text");
  const email = useField("email");
  const phone = useField("tel");
  const address = useField("text");
  const langues = useField("text");
  const domaine = useField("text");
  const experience = useField("text");
  const expertise = useField("text");
  const [file, setFile] = useState();
  const [contentType, setContentType] = useState();
  const [fileName, setFileName] = useState();
  const initialCheckboxes = data.reduce((acc, cur) => {
    acc[cur.value] = false;
    return acc;
  }, {});

  const [checkedItems, handleCheckboxChange, getCheckedValues] =
    useCheckbox(initialCheckboxes);

  const handleFileUpload = async (event) => {
    const baseFile = event.target.files[0];
    setContentType(baseFile.type);
    setFileName(baseFile.name);
    const file64 = await toBase64(baseFile);
    const file64Split = file64.split(",").pop();
    setFile(file64Split);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const checkedValues = getCheckedValues();
    const checkedValuesString = checkedValues.join(",");

    const toSendObject = {
      name: name.value,
      phone: phone.value,
      email: email.value,
      address: address.value,
      langues: langues.value,
      domaine: domaine.value,
      experience: experience.value,
      expertise: expertise.value,
      checklist: checkedValuesString,
      file: file,
      content_type: contentType,
      file_name: fileName,
    };

    console.log(toSendObject);
    formService.postCandidate(toSendObject);
    setSubmitted(true);
  };

  return (
    <div>
      <form className="recruiter-form" onSubmit={handleSubmit}>
        <Input {...name} placeholder="Nom Prénom" required />

        <Flex width="99%">
          <Col twoCol paddingMobile="0" margin="0">
            <Input {...email} placeholder="Email" required />
          </Col>
          <Col twoCol paddingMobile="0" margin="0">
            <Input {...phone} placeholder="Téléphone" required />
          </Col>
        </Flex>

        <Flex width="99%">
          <Col twoCol paddingMobile="0" margin="0">
            <Input {...address} placeholder="Adresse" required />
          </Col>
          <Col twoCol paddingMobile="0" margin="0">
            <Input {...langues} placeholder="Langues" required />
          </Col>
        </Flex>

        <TextArea
          {...domaine}
          placeholder="Domaine de compétences"
          required
        ></TextArea>

        <TextArea
          {...experience}
          placeholder="Expérience professionnelle"
          required
        ></TextArea>

        <TextArea
          {...expertise}
          placeholder="Sujet d'expertise"
          required
        ></TextArea>

        <div className="enterprise-checkboxes">
          <Contain Secondary paddingMobile="5px">
            SECTEUR D'ACTIVITÉ :
          </Contain>
          <div className="checkboxes-list">
            {data.map((checkbox) => (
              <label>
                {checkbox.label}
                <input
                  type="checkbox"
                  name={checkbox.value}
                  checked={checkedItems.value}
                  onChange={handleCheckboxChange}
                />
              </label>
            ))}
          </div>
        </div>

        <Flex
          width="100%"
          className="enterprise-checkboxes"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Col fiveCol paddingMobile="10px">
            <Contain Secondary paddingMobile="5px">
              VOTRE CV :
            </Contain>
          </Col>
          <Col>
            <label for="file" class="label-file">
              Choisir un fichier
            </label>
            <input
              id="file"
              type="file"
              className="input-file"
              onChange={handleFileUpload}
            />
          </Col>
        </Flex>
        <Contain Center>
          <Button Primary type="submit" value="Envoyer" />
        </Contain>
      </form>
    </div>
  );
}

export default RecruiterForm;
