import common from "./themes/common";
import styled, { css } from "styled-components";

const Button = styled.button`
  padding: 12px 27px;
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  text-transform: ${common.transforms.initial};
  font-size: ${common.sizes.desktop.normal};
  transition: all 0.5s ease-in-out;
  min-width: 195px;
  margin: ${(props) => props.margin || "initial"};
  font-family: 'Helvetica';

  @media ${common.media.mobile} {
    width: ${common.width.full};
  }

  ${(props) =>
    Object.keys(common.weights).reduce((acc, weight) => {
      if (props[weight]) {
        return acc + `font-weight: ${common.weights[weight]};`;
      }
      return acc;
    }, `font-weight: ${common.weights.bold};`)};

  ${(props) =>
    Object.keys(common.width).reduce((acc, width) => {
      if (props[width]) {
        return acc + `width: ${common.width[width]};`;
      }
      return acc;
    }, `width: ${common.width.auto};`)};

  ${(props) =>
    props.primary &&
    css`
      background: ${props.theme.colors.btnPrimary};
      border-color: ${props.theme.colors.btnPrimary};
      color: ${props.theme.colors.light};
      border-radius: ${props.theme.radius.button};
      &:hover {
        background: ${props.theme.colors.btnPrimaryHover};
        border-color: ${props.theme.colors.btnPrimaryHover};
        color: ${props.theme.colors.light};
      }
    `}
  ${(props) =>
    props.secondary &&
    css`
      background: ${props.theme.colors.btnSecondary};
      border-color: ${props.theme.colors.btnSecondary};
      color: ${props.theme.colors.light};
      border-radius: ${props.theme.radius.button};
      &:hover {
        background: ${props.theme.colors.btnSecondaryHover};
        border-color: ${props.theme.colors.btnSecondaryHover};
        color: ${props.theme.colors.light};
      }
    `}
  ${(props) =>
    props.search &&
    css`
      background: ${props.theme.colors.btnSearch};
      border-color: ${props.theme.colors.btnSearch};
      color: ${props.theme.colors.light};
      border-radius: ${props.theme.radius.search};
      &:hover {
        background: ${props.theme.colors.btnSearchHover};
        border-color: ${props.theme.colors.btnSearchHover};
        color: ${props.theme.colors.light};
      }
    `}
`;

export default Button;
