export const getHash = (key) => {
  var hash = window.location.hash
    .split("?")
    .pop()
    .split("&")
    .map((v) => v.split("="))
    .reduce((pre, [key, value]) => ({ ...pre, [key]: value }), {});

  return hash[key];
};

export const getParams = (key) => {
  var hash = window.location.href
    .split("?")
    .pop()
    .split("&")
    .map((v) => v.split("="))
    .reduce((pre, [key, value]) => ({ ...pre, [key]: value }), {});

  return hash[key];
};
