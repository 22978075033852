/* eslint-disable eqeqeq */
import React, { useState } from "react";
import {
  Flex,
  Input,
  Select,
  TextArea,
  Button,
} from "../styledComponent/index";
import "./Form.css";

import { useSelector } from "react-redux";

const CategoriesDropdown = ({
  selectedCategories,
  handleSetCategory,
  handleSetSubCategory,
  required,
}) => {
  let categories = Object.keys(useSelector((state) => state.data.trainings));
  categories.sort((a, b) =>
    a.localeCompare(b, "fr", { ignorePunctuation: true })
  );
  categories.unshift("- Aucune -");

  const [isOpen, setOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  // const [items, setItem] = useState(data)

  const toggleDropdown = () => setOpen(!isOpen);

  const handleItemClick = (item) => {
    toggleDropdown();

    if (selectedCategories === item || item === "- Aucune -") {
      handleSetCategory(null);
      if (selectedCategories !== item) {
        handleSetSubCategory(null); // Set handleSetSubCategory to null when handleSetCategory changes to a different item
      }
    } else {
      handleSetCategory(item);
      handleSetSubCategory(null); // Set handleSetSubCategory to null when handleSetCategory changes to a different item
    }
  };

  return (
    <Select className="dropdownSelect" onClick={toggleDropdown}>
      <div className="form-dropdown">
        <div className="dropdown-header">
          {/* {console.log(selectedCategories)} */}
          {selectedCategories ? (
            selectedCategories
          ) : (
            <span className="default-category">Catégorie</span>
          )}
        </div>
        <div className={`dropdown-body ${isOpen && "open"}`}>
          {categories.map((item) => (
            <div
              key={categories.indexOf(item)}
              className="dropdown-item"
              onClick={(e) => handleItemClick(item)}
              id={categories.indexOf(item)}
            >
              <span
                className={`dropdown-item-dot ${
                  categories.indexOf(item) == selectedCategories && "selected"
                }`}
              >
                •{" "}
              </span>
              {item}
            </div>
          ))}
        </div>
      </div>
    </Select>
  );
};

export default CategoriesDropdown;
