export const arel = {
  /** LOGO **/
  logo: "https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/0ccff6d4-930b-497b-56ea-725067437500/public",

  /** TITLE ONGLET **/
  title_onglet: "Bienvenue sur Arel Formation",

  /** MENU **/
  home_menu: "Accueil",
  our_catalog_menu: "Catalogue",
  about_us_menu: "Qui sommes-nous ?",
  contact_menu: "Contact",
  formateur_menu: "Formateur",

  /**********************************************************************/
  /** HOME PAGE **/

  // TEXTES
  home_hero_subtitle: "Découvrez une nouvelle façon d’apprendre et développer vos compétences avec AREL Formations",

  // CSS
  home_hero_search_radius: "0",
  form_radius: "0",
  home_steps_background: "linear-gradient(0deg, rgba(254,239,235,1) 0%, rgba(255,255,255,1) 100%)",

  // IMAGES
  home_hero_background: "https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/fa29fd30-bb0a-4682-bbc7-008f142bc400/public",
  home_testimonial_background: "",
  home_promesse_excellence: "https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/a05bce2e-2bc5-4130-b182-cb1d88e9a400/public",
  home_promesse_passion: "https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/8bae20a0-54fb-4a7a-744e-3e1211bc5b00/public",
  home_promesse_bienveillance: "https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/47ea971b-9bd8-480b-1956-6c0a85bdb500/public",
  home_promesse_impact: "https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/13bf8627-45a5-469c-96ca-909157401700/public",
  home_get_back: "https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/5fddef29-c987-4818-29a6-25211a186000/public",
  

  /**********************************************************************/
  /** FOOTER **/

  // IMAGES
  footer_backForm: "",

};

