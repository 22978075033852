import facilit from "./facilit";
import arel from "./arel";
import skills from "./skills";

const themes = {
  arel: arel,
  facilit: facilit,
  skills: skills,
};

export default themes;
