import React from "react";
import "./TrainingDescription.css";
import ElearningObjectivesProgram from "../../learningCPF/elearningObjectivesProgram/ElearningObjectivesProgram";
// import EssentialInfo from "../../general/essentialInfo/EssentialInfo"
import EssentialInfo from "../essentialInfo/EssentialInfo";
import { H2 } from "../../styledComponent/index";

const TrainingDescription = ({ training }) => {
  return (
    <div className="elearning-description-container">
      <div>
        <h2>
          {training.name} {training.elearning ? "- E-learning" : ""}
        </h2>

        <div className="objectives-infos">
          {/* OBJECTIVES & PROGRAM */}
          <ElearningObjectivesProgram training={training} />

          {/* ESSENTIAL INFO  */}
          <div className="info-container">
            <EssentialInfo
              training={training}
              sectionWidth="491px"
              fontSize="18px"
              cardWidth="220px"
              cardHeight="95px"
              lineHeight="auto"
              margin="42px 0 0 20px"
              elearning
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingDescription;
