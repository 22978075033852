import common from "./themes/common";
import styled, { css } from "styled-components";

const H4 = styled.h4`
  line-height: 35px;
  margin: ${(props) => props.margin || "initial"};

  ${(props) =>
    Object.keys(common.sizes.desktop).reduce((acc, size) => {
      if (props[size]) {
        return acc + `font-size: ${common.sizes.desktop[size]};`;
      }
      return acc;
    }, `font-size: ${common.sizes.desktop.medium};`)};

  ${(props) =>
    Object.keys(props.theme.colors).reduce((acc, color) => {
      if (props[color]) {
        return acc + `color: ${props.theme.colors[color]};`;
      }
      return acc;
    }, `color: ${props.theme.colors.primary};`)};

  ${(props) =>
    Object.keys(common.aligns.desktop).reduce((acc, align) => {
      if (props[align]) {
        return acc + `text-align: ${common.aligns.desktop[align]};`;
      }
      return acc;
    }, `text-align: ${props.hebrew ? common.aligns.desktop.right : common.aligns.desktop.left};`)};

  ${(props) =>
    Object.keys(common.transforms).reduce((acc, transform) => {
      if (props[transform]) {
        return acc + `text-transform: ${common.transforms[transform]};`;
      }
      return acc;
    }, `text-transform: ${common.transforms.initial};`)};

  ${(props) =>
    Object.keys(common.weights).reduce((acc, weight) => {
      if (props[weight]) {
        return acc + `font-weight: ${common.weights[weight]};`;
      }
      return acc;
    }, `font-weight: ${common.weights.semibold};`)};

  @media ${common.media.mobile} {
    ${(props) =>
      Object.keys(common.sizes.mobile).reduce((acc, size) => {
        if (props[size]) {
          return acc + `font-size: ${common.sizes.mobile[size]};`;
        }
        return acc;
      }, `font-size: ${common.sizes.mobile.mediumM};`)};

    ${(props) =>
      Object.keys(common.aligns.mobile).reduce((acc, align) => {
        if (props[align]) {
          return acc + `text-align: ${common.aligns.mobile[align]};`;
        }
        return acc;
      }, `text-align: ${common.aligns.mobile.centerM};`)};
  }
`;

export default H4;
