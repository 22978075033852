import React from "react";
import "./Search.css";
import { FiSearch } from "react-icons/fi";

import { useSelector, useDispatch } from "react-redux";
import { setSearchFilter } from "../../../reducers/dataReducer";
import MediaQuery from "react-responsive";
import { Flex } from "../../styledComponent/index";

const Search = ({ onSearch }) => {
  const dispatch = useDispatch();
  const searchFilter = useSelector((state) => state.data.search_filter);
  // const [searchVal, setSearchVal] = useState("")

  const handleInput = (event) => {
    dispatch(setSearchFilter(event.target.value));
    onSearch(event.target.value);
  };

  return (
    <div id="search">
      <MediaQuery maxWidth={813}>
        <p className="titleSearch">Recherche par mot-clé</p>
      </MediaQuery>
      <input
        onChange={handleInput}
        value={searchFilter}
        type="text"
        name="product-search"
        id="product-search"
        placeholder="Rechercher..."
      />
      <Flex primary className="ico-search">
        <FiSearch color="#fff" />
      </Flex>
    </div>
  );
};

export default Search;
