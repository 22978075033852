import React, { useState, useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaChevronRight } from "react-icons/fa";

// THEMES
import {
  Container,
  Flex,
  Contain,
  Col,
  H1,
  H2,
  Button,
  Space,
  Span,
} from "../styledComponent/index";

const Counter = ({ target, duration, suffix, title }) => {
  // ORGANISMES
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const [count, setCount] = useState(0);

  useEffect(() => {
    if (!target || !duration) {
      return;
    }

    const interval = duration / target;
    let currentCount = 0;

    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        currentCount++;
        if (currentCount > target) {
          clearInterval(intervalId);
          return target;
        }
        return prevCount + 1;
      });
    }, interval);

    return () => clearInterval(intervalId);
  }, [target, duration]);

  return (
    <div>
      <H1 left>
        {" "}
        {count} {suffix}{" "}
      </H1>
      <Contain left> {title} </Contain>
    </div>
  );
};

const DataCounters = () => {
  // ORGANISMES
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const [isVisible, setIsVisible] = useState(false);
  const countersRef = useRef(null);

  const contentReducer = useSelector((state) => state.data.website_content);

  const { pathname } = useLocation();
  const isHomepage = pathname === "/";

  const countersData = useMemo(() => {
    if (!contentReducer) return [];

    const numbers = contentReducer?.filter(
      (item) => item.type === "NUMBER" || item.type === "PERCENT"
    );

    return (
      numbers?.slice(0, 4).map(({ value, long_value, type }) => ({
        target: value,
        duration: type === "PERCENT" ? 3000 : 1000,
        suffix: type === "PERCENT" ? "%" : "",
        title: long_value,
      })) || []
    );
  }, [contentReducer]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;
      setIsVisible(entry.isIntersecting);
    });

    observer.observe(countersRef.current);

    return () => observer.disconnect();
  }, []);

  return (
    <Container boxed>
      <H1 margin="0 0 20px">
        <Span>Nous sommes</Span> fiers.
      </H1>
      <H2>
        Nous sommes fiers de ces chiffres, mais nous sommes encore plus fiers
        des histoires individuelles de succès et développement personnel que
        nous contribuons à créer.
      </H2>
      <Space height="50px" />
      <Flex className="listing" width="100%" ref={countersRef}>
        {countersData.map((counter, index) => (
          <Col className="col-numbers" key={index}>
            {isVisible && (
              <Counter
                target={counter.target}
                duration={counter.duration}
                suffix={counter.suffix}
                title={counter.title}
              />
            )}
          </Col>
        ))}
      </Flex>
      <Space height="50px" />
      <Contain center bold medium>
        Rejoignez notre communauté d'apprenants dès aujourd'hui et découvrez
        comment Facilit peut vous aider à atteindre vos objectifs
        professionnels.
      </Contain>
      <Space height="50px" />
      <Contain center>
        <Button className="take_appointment" primary>
          Contactez-nous <FaChevronRight />
        </Button>
      </Contain>
    </Container>
  );
};

export default DataCounters;
