import React, { useState } from "react"
import "./Faq.css"
import { useSelector } from "react-redux"

const Faq = () => {
  const [openItemIndex, setOpenItemIndex] = useState(null)

  const toggleItem = (index) => {
    setOpenItemIndex((prevIndex) =>
      prevIndex === index ? null : index
    )
  }

  const faqImageObject = useSelector(
    (state) =>
      state.data.website_content?.filter(
        (item) => item.content_id === "faq"
      ) ?? []
  )

  const faqImage = faqImageObject[0]?.value

  const questions = useSelector(
    (state) =>
      state.data.training_content?.filter((item) =>
        item.content_id.startsWith("faq_question")
      ) ?? []
  )

  const responses = useSelector(
    (state) =>
      state.data.training_content?.filter((item) =>
        item.content_id.startsWith("faq_response")
      ) ?? []
  )

  console.log('questions', questions)
  console.log('responses', responses)
  console.log('true or false', questions.length > 0 && responses.length > 0 ? true : false)

  const displayFaq = questions.length > 0 && responses.length > 0 ? true : false
  console.log('displayFaq', displayFaq)

  const FaqItem = ({ index, question, answer }) => {
    const isOpen = index === openItemIndex

    return (
      <div className={`faq-item ${isOpen ? "open" : ""}`}>
        <div
          className='faq-question'
          onClick={() => toggleItem(index)}>
          {question}
          <span className='faq-icon'>
            {isOpen ? "x" : "+"}
          </span>
        </div>
        {isOpen && (
          <div className='faq-answer'>{answer}</div>
        )}
      </div>
    )
  }



  return (
    <>
      {displayFaq ? (
        <div className='faq-container'>
          <h3>Questions fréquemment posées</h3>
          <div>
            <img src={faqImage} alt='faq' />
            <pre className="faq-section">
              {questions.map((question, index) => {
                const response = responses[index];
                const answer = response?.long_value;
                const formattedAnswer = answer && answer.includes("\\n") ? answer.replace(/\\n/g, "\n") : answer;

                return (
                  <FaqItem
                    key={index}
                    index={index}
                    question={question?.long_value || ""}
                    answer={formattedAnswer || ""}
                  />
                );
              })}
            </pre>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default Faq
// import React, { useState } from "react"
// import "./Faq.css"
// import { useSelector } from "react-redux"

// const Faq = () => {
//   const [openItemIndex, setOpenItemIndex] = useState(null)

//   const toggleItem = (index) => {
//     setOpenItemIndex((prevIndex) =>
//       prevIndex === index ? null : index
//     )
//   }

//   const faqImageObject = useSelector(
//     (state) =>
//       state.data.website_content?.filter(
//         (item) => item.content_id === "faq"
//       ) ?? []
//   )

//   const faqImage = faqImageObject[0]?.value

//   const questions = useSelector(
//     (state) =>
//       state.data.training_content?.filter((item) =>
//         item.content_id.startsWith("faq_question")
//       ) ?? []
//   )

//   const responses = useSelector(
//     (state) =>
//       state.data.training_content?.filter((item) =>
//         item.content_id.startsWith("faq_response")
//       ) ?? []
//   )

//   const FaqItem = ({ index, question, answer }) => {
//     const isOpen = index === openItemIndex

//     return (
//       <div className={`faq-item ${isOpen ? "open" : ""}`}>
//         <div
//           className='faq-question'
//           onClick={() => toggleItem(index)}>
//           {question}
//           <span className='faq-icon'>
//             {isOpen ? "x" : "+"}
//           </span>
//         </div>
//         {isOpen && (
//           <div className='faq-answer'>{answer}</div>
//         )}
//       </div>
//     )
//   }

//   return (
//     <div className='faq-container'>
//       <h3>Questions fréquemment posées</h3>
//       <div>
//         <img src={faqImage} alt='faq' />
//         <div className='faq-section'>
//           {questions.map((question, index) => {
//             const response = responses[index]
//             return (
//               <FaqItem
//                 key={index}
//                 index={index}
//                 question={question?.long_value ?? ""}
//                 answer={response?.long_value ?? ""}
//               />
//             )
//           })}
//         </div>
//       </div>
//     </div>
//   )
// }

// export default Faq
