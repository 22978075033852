import React from "react"
import { useSelector } from "react-redux"
import mediateur from "../../medias/mediateur.png"

import { TermsHeader, TermsContent } from "./TermsStyled"

const TermsConditions = () => {
  // Data Salesforce
  const companyDatas = useSelector(
    (state) => state.data.company
  )
  const companyName = companyDatas?.company

  const imageConditions =
    useSelector((state) =>
      state.data.website_content?.filter(
        (item) => item.content_id === "terms"
      )
    ) || []

  return (
    <div>
      <TermsHeader image={imageConditions[0]?.value}>
        <h4>Conditions Générales de Ventes</h4>
      </TermsHeader>
      <TermsContent>
        <h6>Objet</h6>
        <p>
          Les conditions générales suivantes s’appliquent à
          toute commande de formation proposée par{" "}
          {companyName}.
          <br />
          <br />
          Les modalités concernant les prestations
          spécifiques seront intégrées à la proposition
          faite au Client, notamment en ce qui concerne les
          Bilans de Compétences.
          <br />
          <br />
          Ces dispositions constituent l’intégralité de
          l’accord conclu entre {companyName} et le Client
          concernant les cours proposés par {companyName}.
        </p>
        <h6>
          Les pré-requis pour le bon déroulement des
          formations
        </h6>
        <p>
          Le client doit disposer :
          <br />
          – D’un ordinateur connecté à Internet avec
          connectivité suffisante
          <br />
          – D’un micro avec une bonne qualité de son.
          <br />– Webcam. (Uniquement pour les formations
          incluant une option “visioconférence”.
        </p>
        <h6>Validité des Dossiers</h6>
        <p>
          Les demandes de formations ne sont enregistrées
          par {companyName} qu’après l’inscription et la
          validation sur le site de l’Edof qui est
          nécessaire à la mise en place de la formation.
          <br />
          <br />
          Dès que l’inscription aura été validée, dans un
          délais de 15 jours maximum, un mail sera envoyé au
          client avec ses codes d’accès et ses identifiants.
          Il sera contacté par un admin ou un formateur pour
          lui présenter la plateforme de formation et pour
          l’auditer, *
          <br />
          <br />
          A la fin de la formation, le client pourra passer
          son examen s’il le souhaite (Bright, Tosa, Lillat,
          TOEIC) et il recevra par email une attestation de
          fin de stage.*
          <br />
          <br />
          Le client confirme ne pas être en situation de
          retraité à taux plein et ne pas travailler ds la
          fonction publique
          <br />
          <br />
          Toute déclaration, fausse ou erronée sur ces
          données entrainera une responsabilité directe du
          client sur la prise en charge de sa formation. Si
          la CDC lui demande un remboursement de
          financement, le client ne pourra pas se retourner
          contre {companyName} car il aura fait une fausse
          déclaration
          <br />
          <br />
          Toute commande de formation suppose que le Client
          accepte le contenu du stage, les prés requis et
          les présentes conditions générales de ventes et
          les conditions de confidentialité des données
          personnelles dont il reconnaît avoir pris
          connaissance. Nous informons nos clients que
          toutes les conversations sont enregistrées afin
          que notre service clients puisse statuer sur
          d’éventuels litiges ou autres reclamations
          <br />
          *sauf pour le Bilan de Compétences qui prévoit un
          accompagnement sur mesure :
          <br />
          <br />
          – 3 phases tout au long de la formation : la phase
          préliminaire pour la co-définition des attentes et
          objectifs, la phase d’investigation qui permet de
          co-élaborer le projet et enfin la phase de
          conclusion dans laquelle le projet est
          co-formalisé.
          <br />– 1 phase 4 qui consiste en un suivi de 6
          mois si le besoin existe pendant la période de
          mise en œuvre du projet.
        </p>
        <h6>Tarifs</h6>
        <p>
          Les tarifs applicables aux formations sont ceux
          figurant en Euros sur le site de l’Edof et sur le
          site Internet d’{companyName}.
          <br />
          <br />
          Ces tarifs sont applicables à la date de début du
          cours. Ils sont susceptibles de modifications. Ces
          tarifs s’entendent tous frais compris. Ils ne
          comprennent pas le matériel nécessaire au bon
          déroulement de la formation (Denrées, vins…etc.)
          <br />
          <br />
          En cas d’accord de formation, la facture sera
          envoyée à la CDC.
        </p>
        <h6>Annulation et remplacement de stagiaires</h6>
        <p>
          Le Client peut, préalablement au début de la
          formation, informer {companyName} du souhait
          d’annuler sa formation et ce dans un délai maximum
          de 72 heures suite à sa date de connection.
          <br />
          <br />
          {companyName} se réserve le droit de refuser
          l’inscription d’un client à une formation en cas
          de non éligibilité de la personne concernée.
          <br />
          <br />
          Toute annulation de la part du Client doit être
          signalée par courrier et confirmée par Mail à{" "}
          {companyName} au plus tard 72 heures ouvrées avant
          le début de la formation.
          <br />
          <br />
          Dépasser ce délai, le client pourra uniquement
          reporter la date de début de formation d’un mois
          maximum et dans ce cas, la date de début de
          formation pourra être repoussée.
          <br />
          <br />
          ☞Pour toute annulation après ses 72h ou durant la
          formation le client, ne pourra prétendre à aucun
          remboursement de la part de {companyName}.
        </p>
        <h6>LITIGE – MÉDIATION DE LA CONSOMMATION</h6>
        <p>
          En cas de litige entre le Client et l’entreprise,
          ceux-ci s’efforceront de le résoudre à l’amiable
          (le Client adressera une réclamation écrite auprès
          du professionnel ou, le cas échéant, auprès du
          Service Relations Clientèle du professionnel).A
          défaut d’accord amiable ou en l’absence de réponse
          du professionnel dans un délai raisonnable d’un
          (1) mois, le Client consommateur au sens de
          l’article L.133-4 du code de la consommation a la
          possibilité de saisir gratuitement, si un
          désaccord subsiste, le médiateur compétent inscrit
          sur la liste des médiateurs établie par la
          Commission d’évaluation et de contrôle de la
          médiation de la consommation en application de
          l’article L.615-1 du code de la consommation, à
          savoir :La Société Médiation Professionnelle{" "}
          <br />
          www.mediateur-consommation-smp.fr <br />
          24 rue Albert de Mun - 33000 Bordeaux
          <br /> <br />
          <img src={mediateur} alt='Mediateur' />
        </p>
      </TermsContent>
    </div>
  )
}

export default TermsConditions
