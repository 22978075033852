// Breakpoints
let FullWidth = "100%";
let BoxedWidth = "1240px";
let TabletWidth = "1024px";
let MobileWidth = "767px";

const common = {
  media: {
    desktop: `(min-width: ${BoxedWidth})`,
    tablet: `(max-width: ${TabletWidth})`,
    mobile: `(max-width: ${MobileWidth})`,
  },
  width: {
    full: FullWidth,
    boxed: BoxedWidth,
    auto: "auto",
  },
  sizes: {
    desktop: {
      normal: "16px",
      medium: "20px",
      large: "26px",
      big: "56px",
    },
    tablet: {
      normalT: "16px",
      mediumT: "18px",
      largeT: "22px",
      bigT: "45px",
    },
    mobile: {
      normalM: "16px",
      mediumM: "18px",
      largeM: "22px",
      bigM: "35px",
    },
  },
  weights: {
    book: 300,
    regular: 400,
    middle: 500,
    bold: 700,
  },
  aligns: {
    desktop: {
      left: "left",
      right: "right",
      center: "center",
      justify: "justify",
    },
    mobile: {
      leftM: "left",
      rightM: "right",
      centerM: "center",
      justifyM: "justify",
    },
  },
  transforms: {
    upper: "uppercase",
    lower: "lowercase",
    initial: "initial",
  },
  shadows: {
    shadowSM: "0 2px 2px rgb(0 0 0 / 10%);",
    shadow: "5px 4px 4px rgb(0 0 0 / 15%);",
    shadowLG: "0 8px 28px rgb(0 0 0 / 10%);",
  },
  hebrew: true,
  arel: true,
  skills: true,
};

export default common;
