import React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Container,
  Flex,
  Col,
  Contain,
  H2,
  H3,
} from "../../styledComponent/index";
import { FaRibbon, FaNetworkWired, FaUsers } from "react-icons/fa";

const DataAboutHome = () => {
  // Data Salesforce
  const companyDatas = useSelector((state) => state.data.company);

  const content = useSelector((state) => state.data.website_content) || [];
  const imgPartners =
    content.filter((item) => item.content_id.startsWith("ico-entreprise")) ||
    [];
  const logos = imgPartners.map((item) => item.value);

  const location = useLocation();
  const Gallery = () => {
    return location.pathname === "/" ? (
      <Flex
        margin="0 auto"
        className="gallery"
        width="100%"
        directionMobile="row"
      >
        {logos.map((logo, index) => (
          <Col key={index}>
            <img src={logo} alt={`logo-${index}`} loading="lazy" />
          </Col>
        ))}
      </Flex>
    ) : null;
  };

  // Data
  const aboutTitlesHome = {
    title: "Qui sommes-nous",
    subtitle: `${companyDatas?.company} est dirigée par une équipe expérimentée de 20 ans dans la formation professionnelle, répond de manière proactive aux besoins des professionnels qui cherchent à améliorer les compétences de leur personnel.`,
  };

  const aboutBlocsHome = [
    {
      title: "QUALITE",
      text: `Les programmes sont développés par des experts et les formateurs sont choisis pour leur expertise et leur capacité à transmettre les connaissances de manière engageante.`,
      ico: <FaRibbon />,
    },
    {
      title: "ORGANISATION",
      text: `Les formations proposées sont pratiques et axées sur les résultats, permettant aux employés d'acquérir des compétences concrètes utilisables dans leur travail quotidien.`,
      ico: <FaNetworkWired />,
    },
    {
      title: "ACCOMPAGNEMENT",
      text: `Nos equipes vous assistent pour  pour garantir que votre financement soit approuvée.
            Nous nous efforçons de rendre le processus le plus transparent pour vous permettre de vous concentrer sur votre formation.`,
      ico: <FaUsers />,
    },
  ];

  return (
    <Container id="about" margin="50px auto">
      <Gallery />
      <H2 Primary CenterM>
        {aboutTitlesHome.title}
      </H2>
      <Contain Primary marginT="20px" marginB="40px">
        {aboutTitlesHome.subtitle}
      </Contain>

      <Flex className="blocs">
        {aboutBlocsHome.map((bloc, index) => (
          <Col
            threeCol
            Center
            Primary
            radius="15px"
            widthMobile="96%"
            margin="2%"
            key={index}
          >
            <Contain textAlign="center" Tertiary>
              {bloc.ico}
            </Contain>
            <H3 Tertiary largeM>
              {bloc.title}
            </H3>
            <Contain textAlign="center" Light marginT="20px" marginB="20px">
              {bloc.text}
            </Contain>
          </Col>
        ))}
      </Flex>
    </Container>
  );
};

export default DataAboutHome;
