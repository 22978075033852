import common from "./themes/common";
import styled, { css } from "styled-components";

const ScreenLinks = styled.div`
  ${(props) =>
    Object.keys(props.theme.colors).map(
      (color) =>
        props[color] &&
        css`
          color: ${props.theme.colors[color]};
        `
    )}
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: -25px 0 25px 130px;
  /* z-index: 0 !important; */
  @media screen and (max-width: 768px) {
    margin: 0;
    /* justify-content: center; */
    & > *:not(:last-child) {
      display: none;
    }
  }
`;

export default ScreenLinks;
