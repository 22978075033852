import common from "./themes/common";
import styled, { css } from "styled-components";

const H1 = styled.h1`
  font-size: ${common.sizes.desktop.big};
  line-height: ${common.sizes.desktop.big};
  margin: ${(props) => props.margin || "initial"};
  font-family: ${(props) => props.theme.fonts.font};

  ${(props) =>
    Object.keys(common.weights).reduce((acc, weight) => {
      if (props[weight]) {
        return acc + `font-weight: ${common.weights[weight]};`;
      }
      return acc;
    }, `font-weight: ${common.weights.middle};`)};

  ${(props) =>
    Object.keys(props.theme.colors).reduce((acc, color) => {
      if (props[color]) {
        return acc + `color: ${props.theme.colors[color]};`;
      }
      return acc;
    }, `color: ${props.theme.colors.titlePrimary};`)};

  ${(props) =>
    Object.keys(common.aligns.desktop).reduce((acc, align) => {
      if (props[align]) {
        return acc + `text-align: ${common.aligns.desktop[align]};`;
      }
      return acc;
    }, `text-align: ${common.aligns.desktop.left};`)};

  ${(props) =>
    Object.keys(common.transforms).reduce((acc, transform) => {
      if (props[transform]) {
        return acc + `text-transform: ${common.transforms[transform]};`;
      }
      return acc;
    }, `text-transform: ${common.transforms.initial};`)};

  @media ${common.media.tablet} {
    ${(props) =>
      Object.keys(common.sizes.tablet).reduce((acc, size) => {
        if (props[size]) {
          return acc + `font-size: ${common.sizes.tablet[size]};`;
        }
        return acc;
      }, `font-size: ${common.sizes.tablet.bigT};`)};
  }

  @media ${common.media.mobile} {
    ${(props) =>
      Object.keys(common.sizes.mobile).reduce((acc, size) => {
        if (props[size]) {
          return acc + `font-size: ${common.sizes.mobile[size]};`;
        }
        return acc;
      }, `font-size: ${common.sizes.mobile.bigM};`)};

    ${(props) =>
      Object.keys(common.aligns.mobile).reduce((acc, align) => {
        if (props[align]) {
          return acc + `text-align: ${common.aligns.mobile[align]};`;
        }
        return acc;
      }, `text-align: ${common.aligns.mobile.centerM};`)};
  }
`;

export default H1;
