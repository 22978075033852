import React from "react"
import { useSelector } from "react-redux"
import { HeaderContainer } from "./TrainingHeaderStyled"

const TrainingHeader = ({ training }) => {

  return <HeaderContainer illustration={training.image}></HeaderContainer>
}

export default TrainingHeader
