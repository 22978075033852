import styled from "styled-components"

export const SwitchWrapper = styled.table`
  padding: 5px;

  label.text {
    display: block;
    font-weight: bold;
    font-size: 1.2rem;
    padding: 7px 10px 7px 0;
    cursor: pointer;
  }

  label.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;

    span.slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ececec;
      -webkit-transition: 400ms;
      transition: 400ms;

      &.round {
        border-radius: 34px;
        &:before {
          border-radius: 50%;
        }
      }

      &:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: whitesmoke;
        box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
        -webkit-transition: 400ms;
        transition: 400ms;
      }
    }

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + span.slider {
        background-color: var(--fifth-color);
        &:before {
          -webkit-transform: translateX(26px);
          -ms-transform: translateX(26px);
          transform: translateX(26px);
        }
      }
    }
  }
`
