import React, { useState } from "react";
import MediaQuery from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FiSearch } from "react-icons/fi";
import { FaPhoneAlt, FaChevronRight } from "react-icons/fa";

// COMPONENTS
import DataCounters from "../../components/general/Counter";
import Testimonial from "../../components/testimonial/Testimonial";
import { setSearchFilter, setFilter } from "../../reducers/dataReducer";
import TrainingCardList from "../../components/catalog/trainingCardList/TrainingCardList";

// THEMES
import {
  Flex,
  H1,
  H2,
  H3,
  Col,
  Button,
  Container,
  Background,
  Contain,
  Input,
  Space,
  Span,
} from "../../components/styledComponent/index";

// CSS
import "./Home.css";

const Home = () => {
  // ORGANISMES
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const currentOrganisme = i18n.language;

  // CLASS ORGANISME
  const buttonClass = `btn-${currentOrganisme}`;
  const getClass = `get-${currentOrganisme}`;
  const colorArrowClass = `color-arrow-${currentOrganisme}`;
  const colorBlocClass = `color-bloc-${currentOrganisme}`;

  const buttonProps = {
    primary: currentOrganisme === "facilit",
    secondary: currentOrganisme !== "facilit",
  };
  const backProps = {
    primaryLight: currentOrganisme === "facilit",
    light: currentOrganisme !== "facilit",
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchVal, setSearchVal] = useState("");

  const handleInput = (event) => {
    setSearchVal(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(setSearchFilter(searchVal));
    dispatch(setFilter(null));
    navigate(`/catalogue`);
  };

  const content = useSelector((state) => state.data.website_content) || [];

  const imgPartners =
    content.filter((item) => item.content_id.startsWith("ico-entreprise")) ||
    [];
  const logos = imgPartners.map((item) => item.value);

  const Gallery = () => {
    return location.pathname === "/" ? (
      <Flex wrap="wrap">
        {logos.map((logo, index) => (
          <Col key={index} twoCol>
            <img src={logo} alt={`logo-${index}`} loading="lazy" />
          </Col>
        ))}
      </Flex>
    ) : null;
  };

  return (
    <div id="home">
      {/** HERO **/}
      <Background
        full
        img={`url(${t("home_hero_background")})`}
        position="bottom"
        height="100vh"
      >
        <Container boxed height="100%">
          <Col
            twoCol
            height="100%"
            margin="0"
            CenterM
            style={{ display: "flex" }}
            paddingMobile="0"
          >
            <Flex
              direction="column"
              alignHor="center"
              width="100%"
              heightMobile="450px"
              alignHorMobile="flex-end"
            >
              <H1 margin="0 0 20px">
                Révélez tout votre potentiel professionnel
              </H1>
              <H2>
                {t("home_hero_subtitle")}
              </H2>

              <Space height="50px" />

              <form onSubmit={handleSubmit} id="search-hero">
                <Contain bold padding="0 0 5px">
                  Je trouve ma formation...
                </Contain>
                <Flex
                  alignVer="center"
                  alignHor="space-between"
                  light
                  shadowSM
                  radius={t("home_hero_search_radius")}
                >
                  <MediaQuery minWidth={768}>
                    <Col center>
                      <FiSearch />
                    </Col>
                  </MediaQuery>
                  <Col twoCol>
                    <Input
                      onChange={handleInput}
                      value={searchVal}
                      type="text"
                      name="product-search"
                      id="product-search"
                      placeholder="Exemple : Pâtisserie "
                    />
                  </Col>
                  <Col twoCol right>
                    <Button
                      search
                      type="submit"
                    >
                      <MediaQuery minWidth={768}>Rechercher</MediaQuery>
                      <FaChevronRight />
                    </Button>
                  </Col>
                </Flex>
              </form>
            </Flex>
          </Col>
        </Container>
      </Background>

      {/** TRAININGS **/}
      <Container full backColorSky padding="100px 0">
        <Container boxed>
          <H1>
            <Span>
              Un festival de formation où <br />
              le scénario
            </Span>{" "}
            est écrit pour vous.
          </H1>

          <Space height="60px" />

          <TrainingCardList />
        </Container>
      </Container>

      {/** CUSTOM **/}
      <Background
        img={`url(https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/5d586afc-c95d-4f96-d133-c778c31da700/public)`}
        padding="50px 0"
        position="top"
      >
        <Flex boxed>
          <Col>
            <H1 margin="0 0 20px">
              <Span>Des formations</Span> sur mesure
            </H1>
            <H2 margin="0 0 20px">
              Débarassez des diktats scolaire et universitaires, avec FACILIT
              <br />
              apprenez efficamenent et sans fioritures grâce à notre
              <br />
              système de personnalisation de formation.
            </H2>
            <Button className="take_appointment" primary>
              Prendre rendez-vous <FaPhoneAlt />
            </Button>
          </Col>
          <Col threeCol></Col>
        </Flex>
      </Background>

      {/** LEARN **/}
      <Background
        img={`url(https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/e24b3327-28c3-4492-88b8-6ac3b467d300/public)`}
        size="30%"
        position="right 40px"
        full
        backColorSky
        padding="80px 0"
      >
        <Container boxed>
          <Col width="60%">
            <H1 margin="0 0 20px">
              <Span>Apprenez... </Span>
              <br />
              Sans quitter votre canapé.
            </H1>
            <H2 margin="0 0 20px">
              Un pannel de formation de haut niveau, à suivre depuis votre
              ordinateur. S’épanouïr n’a jamais été aussi agréable.
            </H2>
          </Col>
          <Space height="150px" />
          <TrainingCardList />
          <Space height="50px" />
          <Button className="take_appointment" primary>
            Voir tout le catalogue <FaChevronRight />
          </Button>
        </Container>
      </Background>

      {/** TESTIMONIAL **/}
      <Background
        full
        img={t("home_testimonial_background")}
        position="center"
        size="cover"
        padding="150px 0"
        {...backProps}
      >
        <Container boxed>
          <H1>
            <Span>Vos histoires font</Span> notre histoire.
          </H1>
          <Space height="48px" />
          <Testimonial />
        </Container>
      </Background>

      {/** PROMISE **/}
      <Container full backColorSky padding="100px 0" id="promise">
        <Container boxed>
          <H1 margin="0 0 20px">
            <Span>Notre promesse.</Span>
          </H1>
          <H2 margin="0 0 50px">
            Des formations d'excellence, animées avec passion, dans un
            <br />
            environnement bienveillant pour un impact réel.
          </H2>
          <Flex wrap="wrap" className="white-bloc">
            <Col twoCol light radius="119px 0 40px 119px">
              <Flex>
                <Col threeCol>
                  <img src={t("home_promesse_excellence")} />
                </Col>
                <Col>
                  <Contain book normal>
                    <b>Excellence</b>
                    <br />
                    Nous visons l'excellence dans tout ce que nous faisons, nos
                    programmes de formation sont soigneusement conçus et animés
                    par des formateurs d'exception. Notre objectif est de vous
                    offrir une expérience inégalée.
                  </Contain>
                </Col>
              </Flex>
            </Col>
            <Col twoCol light radius="0 119px 119px 40px">
              <Flex>
                <Col>
                  <Contain book normal>
                    <b>Passion</b>
                    <br />
                    La passion est le cœur de notre démarche. Nous sommes animés
                    par la soif d'apprendre et nous cherchons à transmettre
                    cette passion à nos étudiants. Nous vous inspirons et
                    cultivons cette passion en vous.
                  </Contain>
                </Col>
                <Col threeCol>
                  <img src={t("home_promesse_passion")} />
                </Col>
              </Flex>
            </Col>
            <Col twoCol light radius="119px 40px 0 119px">
              <Flex>
                <Col threeCol>
                  <img src={t("home_promesse_bienveillance")} />
                </Col>
                <Col>
                  <Contain book normal>
                    <b>Bienveillance</b>
                    <br />
                    Nous nous engageons à avoir un impact réel dans la vie de
                    nos étudiants. Notre objectif est de vous aider à réaliser
                    vos aspirations professionnelles, à atteindre vos objectifs
                    et à ouvrir de nouvelles opportunités. <br />
                    Votre succès est notre plus grande récompense.
                  </Contain>
                </Col>
              </Flex>
            </Col>
            <Col twoCol light radius="40px 119px 119px 0">
              <Flex>
                <Col>
                  <Contain book normal> 
                    <b>Impact</b>
                    <br />
                    L'humain est au cœur de notre approche. <br />
                    La bienveillance, l'empathie et le respect mutuel sont des
                    valeurs fondamentales pour nous. Notre équipe est là pour
                    vous accompagner et vous encourager tout au long de votre
                    parcours de formation.
                  </Contain>
                </Col>
                <Col threeCol>
                  <img src={t("home_promesse_impact")} />
                </Col>
              </Flex>
            </Col>
          </Flex>
        </Container>
      </Container>

      {/** NUMBERS **/}
      <Container full backColorSky {...backProps} padding="100px 0">
        <DataCounters />
      </Container>

      {/** BEST **/}
      <Container full backColorSky {...backProps} padding="100px 0" id="best">
        <Container boxed>
          <H1 left margin="0 0 20px">
            <Span>Les best-sellers</Span> de Facilit.
          </H1>
          <H2 titleLight>
            Nos apprenants ont appréciés ces formations
          </H2>
        </Container>
      </Container>

      {/** GET **/}
      <Background full id="get" className={`${getClass}`}>
        <Background
          full
          img={`url(${t("home_get_back")})`}
          position="top right"
          size="contain"
          padding="100px 0 200px"
        >
          <Flex full alignHor="space-evenly">
            <Col width="50%">
              <H1 margin="0 0 20px">
                Vous souhaitez obtenir
                <br />
                un financement pour
                <br />
                votre formation ?
              </H1>
              <Space height="20px" />
              <H2>
                Grâce aux OPCO, votre entreprise peut obtenir
                <br />
                jusqu’a 100% de financement pour votre formation
              </H2>
              <Space height="50px" />
              <Contain>
                <Button className="take_appointment" secondary>
                  Vérifier mon éligibilité <FaChevronRight />
                </Button>
              </Contain>
            </Col>

            <Col width="20%">
              <Gallery />
            </Col>
          </Flex>
        </Background>
      </Background>

      {/** STEP **/}
      <Background
        full
        img={t("home_steps_background")}
        position="top"
        padding="100px 0"
        id="steps"
      >
        <Container boxed>
          <H1 margin="0 0 20px">
            <Span>1, 2, 3</Span>...Apprenez !
          </H1>
          <H2>
            Commencez dès maintenant en toute facilité :
          </H2>

          <Space height="50px" />

          <Flex className={`${colorArrowClass}`}>
            <Col shadowLG>
              <Contain normal bold center titlePrimary padding='12px 0'>
                Choisissez votre formation
              </Contain>
              <Contain className="arrow-right"></Contain>
            </Col>
            <Col shadowLG>
              <Contain normal bold center titlePrimary padding='12px 0'>
                La prise de contact
              </Contain>
              <Contain className="arrow-right"></Contain>
            </Col>
            <Col shadowLG>
              <Contain normal bold center titlePrimary padding='12px 0'>
                En formation
              </Contain>
              <Contain className="arrow-right"></Contain>
            </Col>
            <Col shadowLG>
              <Contain normal bold center titlePrimary padding='12px 0'>
                Début de l’apprentissage
              </Contain>
              <Contain className="arrow-right"></Contain>
            </Col>
            <Col shadowLG>
              <Contain normal bold center titlePrimary padding='12px 0'>
                Certification
              </Contain>
              <Contain className="arrow-right"></Contain>
            </Col>
          </Flex>

          <Space height="20px" />

          <Flex className={`${colorBlocClass}`}>
            <Col padding="24px" shadowLG>
              <Contain normal book titlePrimary>
                Choisissez la formation adaptée parmi notre catalogue et obtenez
                des informations détaillées sur les parcours auprès de nos
                conseillers.
              </Contain>
            </Col>
            <Col padding="24px" shadowLG>
              <Contain normal book titlePrimary>
                Nos conseillers prennent contact avec vous afin de vérifier avec
                vous les différentes options de financement de votre formation
                et votre inscription à celle-ci.
              </Contain>
            </Col>
            <Col padding="24px" shadowLG>
              <Contain normal book titlePrimary>
                Apprenez à votre rythme, avec un coach pour vous guider tout au
                long de votre formation et des rendez-vous de suivi réguliers
                pour évaluer vos progrès.
              </Contain>
            </Col>
            <Col padding="24px" shadowLG>
              <Contain normal book titlePrimary>
                Une fois inscrit, vous pouvez réservez vos cours avec vos
                formateurs et développez vos compétences en toute autonomie sur
                notre plateforme en ligne.
              </Contain>
            </Col>
            <Col padding="24px" shadowLG>
              <Contain normal book titlePrimary>
                Bravo ! Votre formation est terminée. Obtenez une certification
                officielle pour valoriser vos compétences et vous démarquer
                professionnellement.
              </Contain>
            </Col>
          </Flex>

          <Space height="50px" />
          <Contain right>
            <Button className="take_appointment" primary>
              Voir le catalogue <FaChevronRight />
            </Button>
          </Contain>
        </Container>
      </Background>
    </div>
  );
};

export default Home;
