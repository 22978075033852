import React from "react";
import "./CertificationInfos.css";
import { useSelector } from "react-redux";
import { Contain } from "../../styledComponent/index";

const CertificationInfos = ({ training }) => {
  const infoList = useSelector((state) => state.data.training_content)?.filter(
    (item) => item.content_id.startsWith("product_details")
  );

  console.log("info", infoList);

  /*  const data = training.contcontents.filter( stqrtsWIth productdetails_table)
  .map(
    {
      name: item;description,
      content: item;vqlue
    }
  ) */
  return (
    <div className="certifications-infos-supcontainer">
      <div className="certification-infos-container">
        {infoList?.map((info, index) => (
          <div
            className={`${index % 2 === 0 ? "blue" : "white"} rectangle`}
            key={index}
          >
            <Contain textAlign="left" Primary className="rectangle-name">
              {info.desc}
            </Contain>
            <pre className="rectangle-content">
              {/* <pre> */}
              {(info?.long_value ?? "").replace(/\\n/g, "\n")}
              {/* </pre> */}
            </pre>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CertificationInfos;
