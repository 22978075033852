import common from "./themes/common";
import styled, { css } from "styled-components";

const Separate = styled.div`
  width: ${(props) => props.width || "90%"};
  height: 25px;
  margin: ${(props) => props.margin || "20px auto"};
  background-image: url(${(props) => props.icon || "/"});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;

  ${(props) =>
    Object.keys(props.theme.colors).map(
      (color) =>
        props[color] &&
        css`
          &:before {
            display: block;
            content: "";
            border-bottom: 0;
            flex-grow: 1;
            border-top: ${props.theme.colors[color]} 1px solid;
            box-sizing: border-box;
            margin-left: ${props.hebrew ? "30px" : "0"};
            margin-right: ${props.hebrew ? "0" : "30px"};
          }
          &:after {
            display: block;
            content: "";
            border-bottom: 0;
            flex-grow: 1;
            border-top: ${props.theme.colors[color]} 1px solid;
            box-sizing: border-box;
            margin-right: ${props.hebrew ? "30px" : "0"};
            margin-left: ${props.hebrew ? "0" : "30px"};
          }
        `
    )}
`;

export default Separate;
