import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { TrainingCardList, Filters } from "./indexCatalog";
import Search from "../../components/general/searchBox/Search";
import DropdownComponent from "../../components/general/dropdown/Dropdown";

import {
  Col,
  Contain,
  Container,
  Flex,
  H1,
  Space,
} from "../../components/styledComponent/index";

const Catalog = ({ filterCategory }) => {
  const location = useLocation();
  const { category = null, subcategory = null } = location.state || {};

  console.log(category, subcategory);

  //SEARCH
  const [searchVal, setSearchVal] = useState("");
  const handleSearch = (val) => {
    setSearchVal(val);
  };

  // FILTER AND SORT
  const [filterBy, setFilterBy] = useState("");
  const [sortBy, setSortBy] = useState("");

  // Data Salesforce
  const contentReducer = useSelector((state) => state.data.website_content);
  const backCatalogue =
    contentReducer &&
    contentReducer.filter((item) => item.content_id === "catalog-hero");

  const handleFilterBy = (value) => {
    setFilterBy(value);
  };

  const handleSortBy = (value) => {
    setSortBy(value);
  };

  return (
    <Container boxed id="catalog">
      <H1 titleSecondary margin="45px 0">
        Catalogue de formation
      </H1>

      <Flex>
        <Col fourCol>
          <Contain>
            Chercher une formation
          </Contain>
          <Space height="10px" />

          <Search onSearch={handleSearch} />

          <Space height="10px" />

          <Filters
            filterBy={filterBy}
            sortBy={sortBy}
            onFilterBy={handleFilterBy}
            onSortBy={handleSortBy}
          />
        </Col>
        <Col>
          {/* <BackLink linkTo='/' linkText="Page d'accueil" /> */}
          <Contain medium bold textSecondary>
            Toutes les formations
          </Contain>
          <TrainingCardList
            searchVal={searchVal}
            filterBy={filterBy}
            sortBy={sortBy}
          />
        </Col>
      </Flex>
    </Container>
  );
};

export default Catalog;
