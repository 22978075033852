export const formatPhoneNumber = (phoneNumber) => {
  // Remove all non-digit characters

  if (phoneNumber === undefined) {
    return "" // Return an empty string or handle the undefined case as needed
  }

  const digitsOnly = phoneNumber?.replace(/\D/g, "")

  // Format the phone number with spaces
  const formattedNumber = digitsOnly?.replace(
    /(\d{2})(?=\d)/g,
    "$1 "
  )

  return formattedNumber
}
