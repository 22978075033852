import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import common from "../styledComponent/themes/common";

import {
  Container,
  Flex,
  Contain,
  Col,
  H4,
  Dot,
} from "../styledComponent/index";

const Loading = () => <div>Loading...</div>;

// TRANSITION
const TestimonialSection = () => {
  // ORGANISMES
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const { website_content, company } = useSelector((state) => {
    return {
      website_content: state.data.website_content,
      company: state.data.company,
    };
  });

  const contentTestimonial =
    website_content &&
    website_content.filter((item) => item.type === "TESTIMONIAL");

  const contentReducer = useSelector((state) => state.data.website_content);

  const starIcon =
    contentReducer &&
    contentReducer.filter((item) => item.content_id === "star-icon");

  // Data
  const testimonials = useMemo(() => {
    if (!contentTestimonial) {
      return [];
    }

    return contentTestimonial.map((item) => ({
      message: item.long_value,
      author: item.value,
      rating: 5,
    }));
  }, [contentTestimonial]);

  const itemsPerGroup = 3;
  const totalGroups = Math.ceil(testimonials.length / itemsPerGroup);

  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentGroupIndex(
        (currentGroupIndex) => (currentGroupIndex + 1) % totalGroups
      );
    }, 4000);
    return () => clearInterval(intervalId);
  }, [totalGroups]);

  if (!contentTestimonial) {
    return <Loading />;
  }

  return (
    <Container id="testimonial">
      <Flex center alignVer="stretch" alignHor="center" wrap="wrap">
        {testimonials
          .slice(
            currentGroupIndex * itemsPerGroup,
            currentGroupIndex * itemsPerGroup + itemsPerGroup
          )
          .map((testimonial, index) => (
            <Col shadowLG testi backTesti padding="50px" key={index}>
              <Flex className="height-bloc" direction='column' alignHor='space-between' alignVer='center'>
                <Contain center className="rating">
                  {Array.from(
                    {
                      length:
                        testimonials[currentGroupIndex * itemsPerGroup]?.rating,
                    },
                    (_, i) => (
                      <img key={i} src={starIcon[0]?.value} alt="star" />
                    )
                  )}
                </Contain>
                <Contain className="desc" center normal bold textTesti>
                  {testimonial.message}
                </Contain>
                <Contain className="name" center normal book textTesti>
                  {testimonial.author}
                </Contain>
              </Flex>
            </Col>
          ))}
      </Flex>
      <Flex alignHor="center" margin="20px">
        {Array.from({ length: totalGroups }, (_, i) => (
          <Dot
            key={i}
            style={{
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              margin: "0 5px",
              background: i === currentGroupIndex ? common.primary : "gray",
            }}
          ></Dot>
        ))}
      </Flex>
    </Container>
  );
};

export default TestimonialSection;
