import React from "react";
import "animate.css";
import {
  EssentialInfoContainer,
  InfosSection,
  InfoCard,
} from "./EssentialInfoStyled";
import { useSelector } from "react-redux";
import { H4, H5 } from "../../styledComponent/index";

const EssentialInfo = (props) => {
  const infos = useSelector((state) => state.data.training_content)?.filter(
    (item) => item.content_id.startsWith("info_essentielles")
  );

  const essentialsInfos = props.training;
  const infoTitles = Object.keys(essentialsInfos);
  return (
    <EssentialInfoContainer
      elearning={props.elearning}
      margin={props.margin}
      lineHeight={props.lineHeight}
    >
      <h3 Primary Initial>
        Informations essentielles
      </h3>
      <InfosSection
        elearning={props.training.online}
        width={props.sectionWidth}
      >
        {infos?.map((item, index) => (
          <InfoCard
            Primary
            key={index}
            elearning={props.training.online}
            cardHeight={props.cardHeight}
            cardWidth={props.cardWidth}
            // className='animate__animated animate__fadeInUp'
          >
            <H5 Primary fontSize={props.fontSize}>
              {item.desc}
            </H5>
            <H4 Light margin="10px 0">
              {item.long_value}
            </H4>
          </InfoCard>
        ))}
      </InfosSection>
    </EssentialInfoContainer>
  );
};

export default EssentialInfo;
