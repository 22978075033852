import styled from "styled-components"

const TermsHeader = styled.div`
  height: 306px;
  width: 100%;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.7) 0%,
      var(--primary-color-light) 54.07%
    ),
    url(${(props) => props.image}) no-repeat center/cover;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
  font-size: 40px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 10px 50px;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    font-size: 30px;
    height: 200px;
    padding: 10px 30px;
  }
`

const TermsContent = styled.div`
  margin: 0 0 50px 50px;
  width: 80%;
  color: var(--primary-color);

  h6 {
    font-size: 25px;
    margin: 20px 0px 10px 0px;
  }
  span {
    font-size: 18px;
  }

  p {
    font-size: 14px;
    font-weight: 300;
    text-align: justify;
    /* margin-bottom: -10px; */
  }
  img {
    width: 20%;
  }
`
export { TermsHeader, TermsContent }
