import React from "react";
import "./StickyBar.css";
import { useSelector } from "react-redux";
import { H3 } from "../../styledComponent/index";
import { NavLink } from "react-router-dom";
import PinkButton from "../pinkButton/PinkButton";
import { Container, Flex } from "../../styledComponent/index";

const StickyBar = ({ training, buttonContent, scrollToAncre }) => {
  const trainingContent = useSelector(
    (state) => state.data.training_content
  )?.filter((item) => item.content_id.startsWith("general_info_1"))[0];
  return (
    <div className="header-bar">
      <Flex alignVer="center">
        {/* /* LEFT-SECTION */}
        <div className="left-section">
          <div className="training-name tracking-in-expand">
            <H3 Primary>{training.name}</H3>
          </div>
          <div className="training-indication">
            {trainingContent?.long_value}
          </div>
        </div>
        {/* RIGHT-SECTION */}
        <div className="right-section">
          <PinkButton
            // later it sould redirect to the configuraator
            training={training}
            to="/contact"
            buttonContent="Commencer ma formation"
          />
        </div>
      </Flex>
    </div>
  );
};

export default StickyBar;
