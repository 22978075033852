import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useField } from "../../hooks/index";
import formService from "../../services/form";
import {
  Flex,
  Input,
  Select,
  TextArea,
  Button,
} from "../styledComponent/index";

import CategoriesDropdown from "./CategoriesDropdown";
import SubCategoriesDropdown from "./SubCategoriesDropdown";
import "./Form.css";

// ICONS
import {
  FaPhoneAlt,
  FaChevronRight,
  FaMapMarkerAlt,
  FaEnvelope,
} from "react-icons/fa";

const Form = ({ setSubmitted, gotCategory, gotSubcategory }) => {
  const lastname = useField("text");
  const firstname = useField("text");
  const email = useField("email");
  const phone = useField("tel");
  const [message, setMessage] = useState("");
  console.log("gotCategory", gotCategory);

  // const message = useField("text")
  const [selectedCategories, setSelectedCategories] = useState("");
  const [selectedSubCategories, setSelectedSubCategories] = useState("");

  const companyDatas = useSelector((state) => state.data.company);
  const trainings = useSelector(
    (state) => state.data.trainings[`${selectedCategories}`]
  );

  // const categories = Object.keys(
  //   useSelector((state) => state.data.trainings)
  // ).sort((a, b) =>
  //   a.localeCompare(b, "fr", { ignorePunctuation: true })
  // )
  const categoryCode = trainings?.find(
    (element) => element.category === selectedCategories
  ).category_id;
  const subcategoryCode = trainings?.find(
    (element) => element.subcategory === selectedSubCategories
  )?.subcategory_id;

  let subCategories = [
    ...new Set(
      trainings
        ?.map((item) => item.subcategory)
        .filter(Boolean)
        .sort((a, b) =>
          a.localeCompare(b, "fr", {
            ignorePunctuation: true,
          })
        )
    ),
  ];
  subCategories.unshift("- Aucune -");

  const handleSetCategory = (item) => {
    setSelectedCategories(item);
  };

  const handleSetSubCategory = (item) => {
    setSelectedSubCategories(item);
  };
  const [error, setError] = useState(null);

  // set the category and subcategory got from catalog
  useEffect(() => {
    if (gotCategory || gotSubcategory) {
      setSelectedCategories(gotCategory);
      setSelectedSubCategories(gotSubcategory === null ? "" : gotSubcategory);
      setMessage(
        `Bonjour, je suis intéressé(e) par une formation ${
          gotSubcategory === null ? "" : gotSubcategory
        } en ${gotCategory}.`
      );
    }
  }, [gotCategory, gotSubcategory]);

  const handleSubmitBasicContactForm = (event) => {
    event.preventDefault();

    // Validate the selected category and subcategory here
    if (!categoryCode || !subcategoryCode) {
      setError("Vous devez sélectionner une catégorie et une sous-catégorie.");
      return;
    }

    const toSendObject = {
      lastname: lastname.value,
      firstname: firstname.value,
      phone: phone.value,
      email: email.value,
      category: categoryCode,
      subcategory: subcategoryCode,
      partner: companyDatas?.salesforce_id,
      message: message,
      // message: message.value,
    };

    formService.create(toSendObject);
    setSubmitted(true);
  };
  //let  the user modify the default message
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  return (
    <form onSubmit={handleSubmitBasicContactForm}>
      <Flex width="100%">
        <Input {...firstname} placeholder="Prénom" required />
        <Input {...lastname} placeholder="Nom" required />
      </Flex>
      <Flex width="100%">
        <Input {...email} placeholder="Email" required />
        <Input {...phone} placeholder="Téléphone" required />
      </Flex>

      <Flex width="100%">
        <CategoriesDropdown
          selectedCategories={selectedCategories}
          handleSetCategory={handleSetCategory}
          handleSetSubCategory={handleSetSubCategory}
          required={true}
        />
        <SubCategoriesDropdown
          selectedSubCategories={selectedSubCategories}
          handleSetSubCategory={handleSetSubCategory}
          subCategories={subCategories}
          required={true}
        />
      </Flex>
      {error && !selectedSubCategories && (
        <p className="error-message">{error}</p>
      )}

      <Flex width="100%">
        <TextArea
          value={message}
          onChange={handleMessageChange}
          // {...message}
          placeholder="Message"
        ></TextArea>
      </Flex>
      <Flex width="100%" alignHor="right">
        <Button primary type="submit" value="Envoyer">
          Envoyer <FaChevronRight />
        </Button>
      </Flex>
    </form>
  );
};
export default Form;
