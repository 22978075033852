import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resources from "./resources/index";
import { getParams } from "./utils";

const url = window.location.href;
const defaultLanguage = url.includes("localhost")
  ? getParams("organism") || "facilit"
  : extId; // eslint-disable-line

i18n.use(initReactI18next).init({
  lng: defaultLanguage,
  fallbackLng: "facilit",
  resources: resources,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
