import styled from "styled-components"

const CustomLink = styled.div`
  margin: 60px 0px 0px 20px;
  font-size: 15px;
  text-align: left;
  span {
    gap: 10px;
    display: flex;
    align-items: center;
  }
`

export { CustomLink }
