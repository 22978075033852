import common from "./themes/common";
import styled, { css } from "styled-components";

const Select = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: ${common.width.full};
  margin-bottom: 15px;
  padding: ${(props) => props.padding || "11px 30px"};
  border-radius: ${(props) => props.radius || "0"};
  border: 1.5px solid #c9c9c9;

  &::placeholder {
    color: ${(props) => props.theme.colors.dark};
    font-weight: ${common.weights.normal};
    font-size: ${common.sizes.normal};
  }

  &:nth-child(n + 2) {
    margin-right: ${(props) => (props.hebrew ? "40px" : "inherit")};
    margin-left: ${(props) => (props.hebrew ? "inherit" : "40px")};
  }

  @media ${common.media.mobile} {
    margin-bottom: 10px;
    &:nth-child(n + 2) {
      margin-right: ${(props) => (props.hebrew ? "0" : "inherit")};
      margin-left: ${(props) => (props.hebrew ? "inherit" : "0")};
    }
  }
`;

export default Select;
