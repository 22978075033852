import common from "./themes/common";
import styled, { css } from "styled-components";
import Container from "./Container";

const Background = styled(Container)`
  background-image: ${(props) => props.img};
  background-size: ${(props) => props.size || "cover"};
  background-position: ${(props) => props.position || "center"};
  background-repeat: no-repeat;
`;

export default Background;
