import common from "./themes/common";
import styled, { css } from "styled-components";

const HiddenBlock = styled.div`
  ${(props) =>
    Object.keys(props.theme.colors).map(
      (color) =>
        props[color] &&
        css`
          background-color: ${props.theme.colors[color]};
        `
    )}
  position:absolute;
  padding: 20px;
  width: 100%;
  margin-top: 7px;
`;

export default HiddenBlock;
