import React from "react";
import ReactDOM from "react-dom/client";
import * as serviceWorker from "./serviceWorker";
import { I18nextProvider } from "react-i18next";
import i18n from "../src/utils/i18n";
import { ThemeProvider } from "styled-components";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import store from "./store/store";
import reportWebVitals from "./reportWebVitals";
import { HashRouter as Router } from "react-router-dom";
import ScrollToTop from "./components/general/ScrollToTop";
import themes from "./components/styledComponent/themes";
import { createBrowserHistory } from "history";
import { getParams } from "./utils/utils";

const history = createBrowserHistory();

const url = window.location.href;
const defaulTheme = url.includes("localhost")
  ? getParams("organism") || "facilit"
  : extId; // eslint-disable-line

ReactDOM.createRoot(document.getElementById("root")).render(
  <Router history={history}>
    <ScrollToTop>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <ThemeProvider theme={themes[defaulTheme]}>
            <App />
          </ThemeProvider>
        </I18nextProvider>
      </Provider>
    </ScrollToTop>
  </Router>
);
reportWebVitals();
