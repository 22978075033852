export const skills = {
  /** LOGO **/
  logo: "https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/3f054280-c489-46ca-c267-4abd336a5300/public",

  /** TITLE ONGLET **/
  title_onglet: "Bienvenue sur Skills to learn",

  /** MENU **/
  home_menu: "Accueil",
  our_catalog_menu: "Catalogue",
  about_us_menu: "Qui sommes-nous ?",
  contact_menu: "Contact",
  formateur_menu: "Formateur",

  /**********************************************************************/
  /** HOME PAGE **/

  // TEXTES
  home_hero_subtitle: "Découvrez une nouvelle façon d’apprendre et développer vos compétences avec Skills to learn",

  // CSS
  home_hero_search_radius: "40px",
  form_radius: "20px",
  home_steps_background: "linear-gradient(0deg, rgba(231,245,238,1) 0%, rgba(255,255,255,1) 100%)",

  // IMAGES
  home_hero_background: "https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/f9c62aec-575a-4293-d503-f2cd29a84d00/public",
  home_testimonial_background: "",
  home_promesse_excellence: "https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/454c098b-077c-4048-442f-152b8c23eb00/public",
  home_promesse_passion: "https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/a04d1091-2376-46d7-4365-82c154004500/public",
  home_promesse_bienveillance: "https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/1db00e4b-a77d-4b01-272f-74af6da2df00/public",
  home_promesse_impact: "https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/219ef81a-24f9-4df8-5119-80a01cc23400/public",
  home_get_back: "https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/eadc296b-9b74-47e9-b7dd-102784afb200/public",
  

  /**********************************************************************/
  /** FOOTER **/

  // IMAGES
  footer_backForm: "",

};

