import styled from "styled-components"

const EssentialInfoContainer = styled.div`
  text-align: left;
  margin: 42px 169px 0 164px;
  margin: ${(props) =>
    props.margin || "42px 169px 0 164px"};

  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: ${(props) => props.lineHeight || "49px"};
    margin-bottom: 5px;
    @media screen and (max-width: 768px) {
      margin: 0 0 20px 0;
    }
  }

  @media screen and (max-width: 768px) {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${(props) => (props.elearning ? "380px" : "")};
  }
`

const InfosSection = styled.div`
  /* width: 1174px; */
  width: ${(props) => props.width || "auto"};
  height: 114px;
  background: var(--fourth-color);
  margin-bottom: 32px;
  padding: 23px 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  flex-wrap: wrap;
  /* width: 410px; */
  row-gap: 15px;
  height: auto;

  z-index: -5 !important;

  @media screen and (max-width: 768px) {
    width: ${(props) =>
      props.elearning ? "100vw" : "fit-content"};
    height: auto;
  }
`

const InfoCard = styled.div`
  height: ${(props) => props.cardHeight || "70px"};
  width: ${(props) => props.cardWidth || "176px"};

  border: 3px solid var(--primary-color);
  background-color: var(--primary-color);
  display: flex;
  color: var(--light-color);
  flex-direction: column;

  @media screen and (max-width: 768px) {
    width: 165px;
    /* height: auto; */
    margin: 0px 5px;
  }
`

const InfoTitle = styled.h5`
  font-size: ${(props) => props.fontSize || "14px"};
  /* font-size: 14px; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--light-color);
  margin-block-start: 0;
  margin-block-end: 0;
  margin-bottom: 2px;
  min-height: 32px;
  padding: 4px 0 4px 0;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
  color: var(--primary-color);

  @media screen and (max-width: 768px) {
    font-size: 13px;
  }
`

const InfoContent = styled.span`
  font-size: ${(props) => props.fontSize || "14px"};
  font-style: normal;
  font-weight: 500;
  /* font-size: 14px; */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 1px 8px;
  color: var(--light-color);
  line-height: 1.1em;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`

export {
  EssentialInfoContainer,
  InfosSection,
  InfoCard,
  InfoTitle,
  InfoContent,
}
