import React from "react"
import { NavLink, Link } from "react-router-dom"
import "./PinkButton.css"

const PinkButton = ({ buttonContent, to, training }) => {
  const { category, subcategory } = training

  return (
    <div className='pink-button'>
      <Link
        to={to}
        state={{
          category: category,
          subcategory: subcategory,
        }}>
        {buttonContent}
      </Link>
    </div>
  )
}

export default PinkButton
