import React from "react";
import { useLocation, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaPhoneAlt } from "react-icons/fa";

import { Flex, Col, Button, Container } from "../styledComponent/index";

import Menu from "./Menu";
import "./Header.css";

const Header = () => {
  // ORGANISMES
  const location = useLocation();
  const { t, i18n } = useTranslation();

  return (
    <Container full light id="header">
      <Flex boxed alignVer="center">
        <Col width="10%" sixCol className="logo">
          <NavLink to="/">
            <img src={t("logo")} alt="logo" />
          </NavLink>
        </Col>
        <Col width="60%" center>
          <Menu />
        </Col>
        <Col width="30%" right>
          <Button className="take_appointment" primary>
            Prendre rendez-vous <FaPhoneAlt />
          </Button>
        </Col>
      </Flex>
    </Container>
  );
};

export default Header;
