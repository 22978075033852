import React from "react";
import { useSelector } from "react-redux";
import TrainingCard from "../trainingCard/TrainingCard";
import shortid from "shortid";
import { Flex } from "../../styledComponent/index";

import "./TrainingCardList.css";

const TrainingCardList = ({ searchVal, filterBy, sortBy }) => {
  const rawCourses = useSelector((state) => state.data.trainings);
  const courses = Object.values(rawCourses).flat();
  const searchFilter = useSelector((state) => state.data.search_filter);

  // Filter by
  let filteredTrainings = courses;
  if (filterBy === "Popularité") {
    filteredTrainings = [...courses].sort(
      (a, b) => b.web_rating - a.web_rating
    );
  } else if (filterBy === "Ordre alphabétique") {
    filteredTrainings = [...courses].sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  }

  // Sort by
  if (sortBy === "Formation distanciel") {
    filteredTrainings = filteredTrainings.filter(
      (training) => training.type === "E-Learning"
    );
  } else if (sortBy === "Formation présentiel") {
    filteredTrainings = filteredTrainings.filter(
      (training) => training.type !== "E-Learning"
    );
  }

  const selectedItem = useSelector((state) => state.data.filter);

  // Category Filter
  if (selectedItem) {
    filteredTrainings = filteredTrainings.filter(
      (training) => training.category === selectedItem
    );
  }

  // Search
  filteredTrainings = filteredTrainings.filter((training) =>
    training.name.toLowerCase().includes(searchFilter?.toLowerCase())
  );

  return (
    <Flex key={shortid.generate()} wrap="wrap" alignHor="center">
      {filteredTrainings.map((training) => (
        <TrainingCard key={shortid.generate()} training={training} />
      ))}
    </Flex>
  );
};

export default TrainingCardList;
