import React from "react"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"

import { CustomLink } from './BackLinkStyled'

const BackLink = ({ linkTo, linkText, state }) => {
  return (
    <>
      <Link to={linkTo} state={state}>
        <CustomLink Primary>
          <span>
            <FontAwesomeIcon icon={faChevronLeft} />
            {linkText}
          </span>
        </CustomLink>
      </Link>
    </>
  )
}

export default BackLink
