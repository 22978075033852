import React, { useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import { initialiseTrainingContents } from "../../reducers/dataReducer"
import { useDispatch } from "react-redux"

import Funding from "./Funding"
import DataAboutHome from "../../components/home/aboutSection/About"

import {
  StickyBar,
  TrainingHeader,
  BackLink,
  TrainingDescription,
  CertificationInfos,
  Configurator,
  Faq,
} from "./indexElearning"

import {
  ScreenLinks,
  StickyHeader,
} from "./ElearningScreenStyled"

const ElearningScreen = () => {
  const location = useLocation()

  const { training } = location?.state

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(initialiseTrainingContents(training.id))
  }, [dispatch, training.id])

  const ancreRef = useRef(null)

  const scrollToAncre = () => {
    ancreRef.current.scrollIntoView({ behavior: "start" })
  }

  return (
    <div>
      {/* HEADER  */}
      <StickyHeader>
        <StickyBar
          training={training}
          buttonContent={"Commencer ma formation"}
          scrollToAncre={scrollToAncre}
        />
      </StickyHeader>
      <TrainingHeader training={training} />

      {/* NAVLINKS  */}
      <ScreenLinks>
        {/* <BackLink linkTo='/' linkText="Page d'accueil" /> */}
        <BackLink
          linkTo='/catalogue'
          linkText='Catalogue'
        />
      </ScreenLinks>

      {/* DESCRIPTION  */}
      <TrainingDescription training={training} />

      {/* CERTIFICATION INFOS  */}
      <CertificationInfos training={training} />

      {/* FORMULAS SLIDER  */}
      {/* <Formulas training={training} /> */}
      {/* CONFIGURATOR  */}
       {/* <div
        className='elearning-configurator'
        ref={ancreRef}>
        <Configurator />
      </div> */}

      {/* FINANCEMENT  */}
      <Funding gotTraining={training} />

      {/* FAQ  */}
      <Faq />
      

      <DataAboutHome />
    </div>
  )
}

export default ElearningScreen
