import React from "react";
import { useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { formatPhoneNumber } from "../../utils/FormatedPhone";
import Form from "../form/Form";

import "./Footer.css";

// ICONES
import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
  FaFacebookF,
  FaLinkedinIn,
  FaInstagram,
  FaClock,
} from "react-icons/fa";

// THEMES
import {
  H1,
  H2,
  Ul,
  Span,
  Flex,
  Col,
  Contain,
  Container,
  Background,
  Space,
} from "../styledComponent/index";

const Footer = () => {
  // ORGANISMES
  const location = useLocation();
  const isContactPage = location.pathname === "/contact";

  const { t, i18n } = useTranslation();
  const currentOrganisme = i18n.language;

  const backProps = {
    primaryDark: currentOrganisme === "arel",
    primary: currentOrganisme !== "arel",
  };

  const colorIco = `where-ico-${currentOrganisme}`;

  // Data Salesforce
  const companyData = useSelector((state) => state.data.company);
  const contentReducer = useSelector((state) => state.data.website_content);
  const qualiopi =
    contentReducer &&
    contentReducer.filter((item) => item.content_id === "qualiopi");

  const myDetails = [
    {
      contain: companyData?.address
        ? `${companyData?.address?.street}, ${companyData?.address?.postalCode} ${companyData?.address?.city}, ${companyData?.address?.country}`
        : undefined,
      ico: companyData?.address ? <FaMapMarkerAlt /> : undefined,
    },
    {
      contain: companyData?.email_info ? companyData?.email_info : undefined,
      ico: companyData?.email_info ? <FaEnvelope /> : undefined,
    },
    {
      contain: companyData?.phone
        ? formatPhoneNumber(companyData?.phone)
        : undefined,
      ico: companyData?.phone ? <FaPhoneAlt /> : undefined,
    },
    {
      contain: companyData?.businessHours
        ? companyData?.businessHours
        : undefined,
      ico: companyData?.businessHours ? <FaClock /> : undefined,
    },
  ];

  const mySocialsNetworks = [
    {
      id: "facebook",
      ico: <FaFacebookF />,
      link: /*companyData?.facebook
        ? companyData.facebook
        : "#"*/ "https://www.facebook.fr/",
    },
    {
      id: "linkedin",
      ico: <FaLinkedinIn />,
      link: companyData?.linkedin ? companyData.linkedin : "#",
    },
    {
      id: "instagram",
      ico: <FaInstagram />,
      link: companyData?.instagram ? companyData.instagram : "#",
    },
  ];

  // Data
  const navFooter = [
    {
      title: "Mentions légales",
      link: "/mentions-legales",
    },
    {
      title: "Conditions générales de ventes",
      link: "/conditions-generales",
    },
    {
      title: "Politique de confidentialité",
      link: "/politique-de-confidentialite",
    },
    {
      title: <a href="www.mediateur-consommation-smp.fr">Médiation</a>,
      // link: "www.mediateur-consommation-smp.fr",
    },
  ];

  const general = {
    logo: `${companyData?.footer}`,
    qualiopi: {
      img: qualiopi?.[0]?.value || "",
      text: `Depuis le 25 juin 2020, ${companyData?.company} est certifiée conformément aux exigences du Référentiel National de Certification Qualité des organismes mentionnés à l'article L.6351-1 du Code du travail.

            La certification qualité a été délivrée au titre de la ou des catégories d'actions suivantes : actions de formation. (Voir le certificat)`,
    },
    copyright: {
      developer: `Powered by`,
      ico: `https://s-quared.io/wp-content/uploads/2022/12/cropped-ICON-ON-BLACK.png`,
      link: `https://s-quared.io/`,
      year: `Copyright © ${new Date().getFullYear()} S-QUARED | Tous droits réservés`,
    },
  };

  const navFooterMap = () => (
    <Ul light normal>
      {Object.entries(navFooter).map(([key, { title, link }]) => (
        <li key={key}>
          {link ? (
            <Link to={link}>{title}</Link>
          ) : (
            <a
              href="www.mediateur-consommation-smp.fr"
              target="_blank"
              rel="noreferrer"
            >
              {title}
            </a>
          )}
        </li>
      ))}
    </Ul>
  );

  const socialsMap = () => (
    <Ul light normal>
      {Object.entries(mySocialsNetworks).map(([key, { ico, link }]) =>
        link === "#" ? null : (
          <li key={key}>
            <a href={link} target="_blank" rel="noreferrer">
              {ico}
            </a>
          </li>
        )
      )}
    </Ul>
  );

  return (
    <footer>
      <Background
        full
        img={`url(${t("footer_backForm")})`}
        light
        position="top"
        padding="150px 0"
        id="form"
      >
        <Container boxed>
          <Flex className="relative">
            <Col shadowLG light radius={t("form_radius")} padding="40px">
              <Container>
                <H1>
                  Nous sommes impatient
                  <br />
                  de vous connaître
                </H1>
                <Space height="30px" />
                <Form />
              </Container>
            </Col>
            <Col
              backDetails
              radius={t("form_radius")}
              padding="40px"
              id="where"
            >
              <H2 light center middle>
                Où nous trouver
              </H2>
              <Space height="40px" />
              <Ul light normal className="nav">
                <li>
                  <Span iconPrimary>{myDetails[2].ico}</Span>
                  <a
                    href={`tel:${myDetails[2].contain}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {myDetails[2].contain}
                  </a>
                </li>

                <li>
                <Span iconPrimary>{myDetails[1].ico}</Span>
                  <a
                    href={`mailto:${myDetails[1].contain}?subject=Demande de formation`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {myDetails[1].contain}
                  </a>
                </li>

                <li>
                  <Span iconPrimary>{myDetails[0].ico}</Span>
                  <a
                    href={`https://www.google.com/maps/place/${myDetails[0].contain}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {myDetails[0].contain}
                  </a>
                </li>

                <li>
                  <Span iconPrimary>{myDetails[3].ico}</Span>
                  {myDetails[3].contain}
                </li>
              </Ul>
            </Col>
          </Flex>
        </Container>
      </Background>
      {!isContactPage && (
        <Container full backQualiopi>
          <Flex boxed alignVer="center">
            <Col threeCol>
              <img
                src="https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/0ca40a8e-f0e0-4c86-a6b0-7a5f2e31d100/public"
                alt="qualiopi"
              />
            </Col>
            <Col>
              <Contain primary>{general.qualiopi.text}</Contain>
            </Col>
          </Flex>
        </Container>
      )}

      <Container full padding="90px 0" primary>
        <Flex boxed>
          <Col fourCol>
            <Contain large regular light padding='0 0 20px'>
              Contact
            </Contain>
            {/* {myDetailsMap()} */}
            <Ul light normal>
              <li>
                <span>{myDetails[0].ico}</span>
                <a
                  href={`https://www.google.com/maps/place/${myDetails[0].contain}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {myDetails[0].contain}
                </a>
              </li>

              <li>
                <span>{myDetails[1].ico}</span>
                <a
                  href={`mailto:${myDetails[1].contain}?subject=Demande de formation`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {myDetails[1].contain}
                </a>
              </li>

              <li>
                <span>{myDetails[2].ico}</span>
                <a
                  href={`tel:${myDetails[2].contain}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {myDetails[2].contain}
                </a>
              </li>
            </Ul>
          </Col>
          <Col fourCol>
            <Contain large regular light padding='0 0 20px'>
              Nos offres
            </Contain>
            {navFooterMap()}
          </Col>
          <Col fourCol>
            <Contain large regular light padding='0 0 20px'>
              Informations générales
            </Contain>
            {navFooterMap()}
          </Col>
          <Col fourCol>
            <Contain large regular light padding='0 0 20px'>
              Suivez-nous
            </Contain>
            {socialsMap()}
          </Col>
        </Flex>
      </Container>

      <Container full padding="10px 0" primary>
        <Contain light center book normal>
          {general.copyright.developer}{" "}
          <a href={general.copyright.link} target="_blank" rel="noreferrer">
            <img
              src={general.copyright.ico}
              className="squared"
              alt="squared copyright"
            />
          </a>
          <br />
          {general.copyright.year}
        </Contain>
      </Container>
    </footer>
  );
};

export default Footer;
