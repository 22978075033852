export const facilit = {
  /** LOGO **/
  logo: "https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/1abb2422-8946-46e8-c39f-f8e3a1a62000/public",

  /** TITLE ONGLET **/
  title_onglet: "Bienvenue sur Facilit Formation",

  /** MENU **/
  home_menu: "Accueil",
  our_catalog_menu: "Catalogue",
  about_us_menu: "Qui sommes-nous ?",
  contact_menu: "Contact",
  formateur_menu: "Formateur",

  /**********************************************************************/
  /** HOME PAGE **/

  // TEXTES
  home_hero_subtitle:
    "Découvrez une nouvelle façon d’apprendre et développer vos compétences avec Facilit",

  // CSS
  home_hero_search_radius: "40px",
  form_radius: "20px",
  home_steps_background:
    "linear-gradient(0deg, rgba(239,243,255,1) 0%, rgba(255,255,255,1) 100%)",

  // IMAGES
  home_hero_background:
    "https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/d02f642a-e389-4778-252c-c7924d11a100/public",
  home_testimonial_background: `url(https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/c0778d12-65dd-4cfa-0a12-5e81c3c07c00/public)`,
  home_promesse_excellence:
    "https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/436b9b4e-489e-4079-e72d-4603524ba600/public",
  home_promesse_passion:
    "https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/4c05a953-3dae-4b14-7be4-e9f348044500/public",
  home_promesse_bienveillance:
    "https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/8f3036ac-7eab-4346-ce58-a7f12ba9d200/public",
  home_promesse_impact:
    "https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/1e040ae6-995a-4b68-934e-0305cc731000/public",
  home_get_back:
    "https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/37462fde-a61f-492d-4779-ec107ec91d00/public",

  /**********************************************************************/
  /** FOOTER **/

  // IMAGES
  footer_backForm:
    "https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/39608df4-c1dc-4ec6-4351-6a6951722400/public",
};
