import { createSlice } from '@reduxjs/toolkit'
import formService from './../services/form'

const dataReducerSlice = createSlice({
    name: 'dataReducer',
    initialState: {
        company: {},
        trainings: {},
        search_filter: ''
    },
    reducers: {
      setData(state, action) {
        state = { ...state, categories: action.payload }
        return state
      },
      setFilter(state, action) {
        state = { ...state, filter: action.payload }
        return state
      },
      setCompanyData(state, action) {
        state = { ...state, company: action.payload }
        return state
      },
      setContent(state, action) {
        state = { ...state, website_content: action.payload }
        return state
      },
      setSelectedTraining(state, action) {
        state = { ...state, trainings: action.payload }
        return state
      },
      setTrainingContent(state, action) {
        state =  { ...state, training_content: action.payload }
        return state
      },
      setSearchFilter(state, action) {
        state = { ...state, search_filter: action.payload }
        return state
      }
    }
  })

export const { setData, setFilter, setCompanyData, setContent, setSelectedTraining, setTrainingContent, setTrainings, setSearchFilter } = dataReducerSlice.actions

export const initialiseCategories2 = () => {
  return async dispatch => {
    dispatch(setFilter('null'))
  }
}

export const initialiseAccount = (orgId) => {
    return async dispatch => {
      const datas = await formService.getAccount(orgId)
      dispatch(setCompanyData(datas))
    }
}

export const initialiseContents = (orgId) => {
    return async dispatch => {
      const datas = await formService.getContent(orgId)
      dispatch(setContent(datas))
    }
}

export const initialiseOrganisedContents = (orgId) => {
    return async dispatch => {
      const datas = await formService.getOrganisedContent(orgId)
      dispatch(setSelectedTraining(datas))
    }
}

export const initialiseTrainingContents = (contentId) => {
    return async dispatch => {
      const datas = await formService.getTrainingContent(contentId)
      dispatch(setTrainingContent(datas))
    }
}

export default dataReducerSlice.reducer