import common from "./common";

const arel = {
  ...common,
  colors: {
    primary: "#EB612C", // Orange
    secondary: "#2176C8", // Bleu ciel

    // TITRES
    titlePrimary: "#000", // Noir
    titleSecondary: "#B04921", // Orange foncé

    // TEXTES
    textPrimary: "#EB612C", // Orange
    textSecondary: "#000", // Noir
    textTesti: "#000", // Noir

    // BOUTONS
    btnTake: "#0F70B7", // Bleu ciel
    btnTakeHover: "#21264F",
    btnSearch: "#EB612C",

    btnPrimary: "#0F70B7", // Bleu ciel
    btnPrimaryHover: "#21264F",

    btnSecondary: "#EB612C", // Orange
    btnSecondaryHover: "#B04921",

    // ICONS
    iconPrimary: "#000", // Noir

    // BACKGROUND
    backColorSky: "#fff0ea",
    backDetails: "#EB612C",
    backTesti: "linear-gradient(180deg, rgba(255,243,240,1) 0%, rgba(255,255,255,1) 100%)",
    backQualiopi: "#FCE7DF",
    

    green: "#48E100",
    red: "#E32652",

    light: "#fff",
    dark: "#000",
  },
  fonts:  {
    font: "HelveticaNeue",
  },
  radius: {
    button: "0px",
    search: "5px",
    testi: "0px",
  },
};

export default arel;
