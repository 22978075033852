import React, { useState } from "react";
import shortid from "shortid";
import "./ElearningObjectivesProgram.css";
import { useSelector } from "react-redux";
import { Contain } from "../../styledComponent/index";

const ElearningObjectivesProgram = ({ training }) => {
  const [showProgram, setShowProgram] = useState(false);
  const [borderBottom, setBorderBottom] = useState(false);
  const [isProgramHovered, setIsProgramHovered] = useState(false);

  const content = useSelector((state) => state.data.training_content)?.filter(
    (item) => item.content_id.startsWith("objectives_table")
  );

  const program = useSelector((state) => state.data.training_content)?.filter(
    (item) => item.content_id.startsWith("program_table")
  );

  return (
    <div>
      {/* OBJECTIVES & PROGRAM  */}
      <div className="elearning-objectives-program">
        <div className="objectives-program-header">
          <h4
            className={
              isProgramHovered ? "program-category-hover" : "default-category"
            }
            onMouseEnter={() => {
              setShowProgram(false);
              setBorderBottom(false);
            }}
          >
            Objectifs de la formation
          </h4>

          {/* PROGRAMME  */}
          {/* <h4
            className={
              borderBottom
                ? "program-title-border"
                : "program-title"
            }
            onMouseEnter={() => {
              setShowProgram(true)
              setIsProgramHovered(true)
              setBorderBottom(true)
            }}>
            Programme
          </h4> */}
        </div>
        <div className="objectives-program-content">
          {showProgram ? (
            <div
              className="program-content"
              id="scrollbar"
              onMouseEnter={() => {
                setBorderBottom(true);
              }}
              onMouseLeave={() => {
                setShowProgram(false);
                setIsProgramHovered(false);
                setBorderBottom(false);
              }}
            >
              {program?.map((step, index) => (
                <div key={shortid.generate()}>
                  <Contain Primary>{step?.long_value}</Contain>
                </div>
              ))}
            </div>
          ) : (
            <div className="objective-content" id="scrollbar">
              {content?.map((objective, index) => (
                <pre key={shortid.generate()}>
                  <Contain Primary>
                    {objective?.long_value.replace(/\\n/g, "\n")}
                  </Contain>
                </pre>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ElearningObjectivesProgram;
