import common from "./themes/common";
import styled, { css } from "styled-components";

const Container = styled.div`
  @media ${common.media.desktop} {
    ${(props) =>
      Object.keys(common.width).map(
        (width) =>
          props[width] &&
          css`
            width: ${common.width[width]};
          `
      )};
  }

  height: ${(props) => props.height || "auto"};
  margin: ${(props) => props.margin || "auto"};
  padding: ${(props) => props.padding || "0"};
  border-radius: ${(props) => props.radius || "0"};

  ${(props) =>
    Object.keys(props.theme.colors).reduce((acc, color) => {
      if (props[color]) {
        return acc + `background-color: ${props.theme.colors[color]};`;
      }
      return acc;
    }, ``)};

  ${(props) =>
    Object.keys(common.shadows).reduce((acc, shadow) => {
      if (props[shadow]) {
        return acc + `box-shadow: ${common.shadows[shadow]};`;
      }
      return acc;
    }, ``)};

  ${(props) =>
    Object.keys(common.aligns.desktop).reduce((acc, align) => {
      if (props[align]) {
        return acc + `text-align: ${common.aligns.desktop[align]};`;
      }
      return acc;
    }, `text-align: ${props.hebrew ? common.aligns.desktop.right : common.aligns.desktop.left};`)};

  @media ${common.media.tablet} {
    width: ${common.width.full};
  }

  @media ${common.media.mobile} {
    width: ${common.width.full};
    margin: ${(props) => props.marginMobile || "auto"};
    padding: ${(props) => props.paddingMobile || "10px"};

    ${(props) =>
      Object.keys(common.aligns.mobile).reduce((acc, align) => {
        if (props[align]) {
          return acc + `text-align: ${common.aligns.mobile[align]};`;
        }
        return acc;
      }, ``)};
  }
`;

export default Container;
