import common from "./themes/common";
import styled, { css } from "styled-components";

const Contain = styled.p`
  margin: ${(props) => props.margin || "initial"};
  padding: ${(props) => props.padding || "0"};
  font-family: 'Helvetica';

  ${(props) =>
    Object.keys(common.weights).reduce((acc, weight) => {
      if (props[weight]) {
        return acc + `font-weight: ${common.weights[weight]};`;
      }
      return acc;
    }, `font-weight: ${common.weights.regular};`)};

  ${(props) =>
    Object.keys(common.sizes.desktop).reduce((acc, size) => {
      if (props[size]) {
        return acc + `font-size: ${common.sizes.desktop[size]};`;
      }
      return acc;
    }, `font-size: ${common.sizes.desktop.medium};`)};

  ${(props) =>
    Object.keys(props.theme.colors).reduce((acc, color) => {
      if (props[color]) {
        return acc + `color: ${props.theme.colors[color]};`;
      }
      return acc;
    }, `color: ${props.theme.colors.textPrimary};`)};

  ${(props) =>
    Object.keys(common.aligns.desktop).reduce((acc, align) => {
      if (props[align]) {
        return acc + `text-align: ${common.aligns.desktop[align]};`;
      }
      return acc;
    }, `text-align: ${common.aligns.desktop.left};`)};

  ${(props) =>
    Object.keys(common.transforms).reduce((acc, transform) => {
      if (props[transform]) {
        return acc + `text-transform: ${common.transforms[transform]};`;
      }
      return acc;
    }, `text-transform: ${common.transforms.initial};`)};

  ${(props) =>
    props.link &&
    css`
      text-decoration: underline;
    `}

  @media ${common.media.tablet} {
    ${(props) =>
      Object.keys(common.sizes.tablet).reduce((acc, size) => {
        if (props[size]) {
          return acc + `font-size: ${common.sizes.tablet[size]};`;
        }
        return acc;
      }, `font-size: ${common.sizes.tablet.mediumT};`)};
  }

  @media ${common.media.mobile} {
    margin: ${(props) => props.marginM || "initial"};
    padding: ${(props) => props.paddingM || "10px"};

    ${(props) =>
      Object.keys(common.sizes.mobile).reduce((acc, size) => {
        if (props[size]) {
          return acc + `font-size: ${common.sizes.mobile[size]};`;
        }
        return acc;
      }, `font-size: ${common.sizes.mobile.mediumM};`)};

    ${(props) =>
      Object.keys(common.aligns.mobile).reduce((acc, align) => {
        if (props[align]) {
          return acc + `text-align: ${common.aligns.mobile[align]};`;
        }
        return acc;
      }, ``)};
  }
`;
export default Contain;
