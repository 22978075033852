import React from "react"
import { useSelector } from "react-redux"
import { formatPhoneNumber } from "../../utils/FormatedPhone"

import { TermsHeader, TermsContent } from "./TermsStyled"

const PrivacyPolicy = () => {
  // Data Salesforce
  const companyDatas = useSelector(
    (state) => state.data.company
  )
  const companyName = companyDatas?.company
  const companyMail = companyDatas?.email
  const companyPhone = formatPhoneNumber(
    companyDatas?.phone
  )

  const companyAddress = `${companyDatas?.address?.street}, ${companyDatas?.address?.postalCode}, ${companyDatas?.address?.city}, ${companyDatas?.address?.country}.`

  const imagePrivacy =
    useSelector((state) =>
      state.data.website_content?.filter(
        (item) => item.content_id === "privacy"
      )
    ) || []

  return (
    <div>
      <TermsHeader image={imagePrivacy[0]?.value}>
        <h4>Politique de Confidentialité</h4>
      </TermsHeader>
      <TermsContent>
        <h6>
          Règles relatives à la protection des personnes
          physiques à l’égard des données à caractère
          personnel de {companyName}
        </h6>
        <span>1. Contexte et cadre juridique</span>
        <p>
          Le Règlement (EU) 2016/679 du Parlement Européen
          et du Conseil du 27 avril 2016 relatif à la
          protection des personnes physiques à l’égard du
          traitement de données à caractère personnel et à
          la libre circulation de ces données, appelé le
          Règlement Général sur la Protection des Données
          (RGPD), fixe le cadre juridique applicable aux
          traitements de données à caractère personnel.
          <br />
          <br />
          Dans ce cadre, nous présentons les modalités de
          respect de cette réglementation, conformément à la
          demande de cette réglementation. En effet,{" "}
          {companyName} gère bien des données personnelles
          dans le cadre de son activité. Cette activité est
          principalement la mise en place de formations pour
          des entreprises.
        </p>
        <span>
          2. Les éléments relatifs à notre application de la
          réglementation <br />
          Les éléments relatifs à la gestion des données
          personnelles, conformément à la réglementation
          sont les suivants :
        </span>
        <p>
          • Les coordonnées du responsable des traitements :{" "}
          {companyName}
          <br />
          • Les finalités des traitements :<br />
          • Recueillir des besoins de formation auprès de
          nos clients par téléphone.
          <br />
          • Accompagner nos clients pour les conseiller dans
          le choix des formations qu’ils souhaitent mettre
          en place
          <br />
          • Monter la demande de prise en charge et une
          convention de formation que l’on envoie à l’OPCA
          du client Faire signer cette demande de prise en
          charge aux clients via « Docusign », Via notre CRM
          ou par Courrier.
          <br />
          • Nos conventions sont accompagnées de nos
          conditions générales de vente et des règles de
          confidentialité que le client doit accepter
          <br />
          • Faire auditer téléphoniquement le client par le
          formateur pour préciser ses besoins en formation
          et pour arrêter des dates de formation.
          <br />
          • Mettre en place la formation
          <br />
          • Réaliser des enquêtes de satisfaction un ou deux
          mois après les formations.
          <br />
          • Origine des données :<br />
          Fiche RDV transmise par des partenaires (aucune
          information sur le client n’est conservée par le
          partenaire)
          <br />
          • RDV téléphonique fait avec les clients par
          téléphone pour affiner leurs besoins
          <br />
          • Conversation téléphonique
          <br />
          • La typologie des données traitées par ces
          opérations :<br />
          • Données d’identification : Nom, Prénom du
          responsable, téléphone, Email, carte d’artisan
          s’il y a lieu. Pour les stagiaires qui sont
          intéressés à suivre la formation : Adresse, date
          de naissance, Numéro de sécurité sociale, Fiche de
          paye et fonction
          <br />• Données financières : Fiche de paye des
          stagiaires exigées par certains OPCA. Ces données
          ne sont pas conservées par {companyName}
          <br />• Les destinataires de ces données :{" "}
          {companyName} les personnes ou organismes les plus
          à même de répondre aux besoins du client et le
          formateur uniquement pour le besoin de formation
          <br />
          • La durée de conservation des données à caractère
          personnel : 5 ans
          <br />
          • Les autres droits de la personne : La
          réglementation demande que les personnes soient
          informées de leurs droits, ce qui est l’objet
          principal de ce texte (explication au-dessus et
          qui suit)
          <br />
        </p>
        <span>
          3. Principes généraux de collecte et traitement
          des données personnelles
        </span>
        <p>
          {companyName} met tout en œuvre pour respecter la
          réglementation lors de la collecte et traitement
          des données personnelles aussi bien en interne,
          qu’en externe, avec nos partenaires et aussi bien
          au niveau informatique qu’organisationnel
          (protection des serveurs et bases de données
          informatiques, gestion des procédures internes,
          gestion des habilitations, information des
          personnes…)
        </p>
        <span>4. Droits des personnes</span>
        <p>
          Les clients et contacts disposent d’un droit de
          demander à {companyName}, le respect de leur
          droits pour les données les concernant. Il s’agit
          notamment des droits suivants :<br />
          <br />
          • Consentement / Suppression du Consentement
          <br />
          • Droit d’accès
          <br />
          • Droit à la modification
          <br />
          • Droit à l’oubli
          <br />
          • Droit à la limitation des traitements <br />
          <br />
          Ces droits sont conditionnés au respect des règles
          suivantes : <br /> <br />
          La demande émane de la personne elle-même et est :{" "}
          <br />
          • Soit accompagnée d’une copie d’un titre
          d’identité, à jour <br />
          • Soit est envoyée de l’Email indiqué dans la
          demande d’assistance <br />• La demande doit être
          formulée par écrit à l’adresse suivante :{" "}
          {companyName}, {companyAddress} ou à l’adresse
          e-mail : {companyMail} <br />
          • La demande doit expliquer précisément l’objet
          exact de la demande d’exercice des droits,
          conformément à la réglementation RGPD <br />
          Conformément à la législation sur la protection
          des données à caractère personnel, les clients et
          contacts sont informés qu’il s’agit d’un droit
          individuel qui ne peut être exercé que par la
          personne concernée relativement à ses propres
          informations : pour des raisons de sécurité, le
          service concerné devra donc vérifier votre
          identité afin d’éviter toute communication
          d’informations confidentielles vous concernant, à
          une autre personne que vous. <br />
          <br />
          Les clients et contacts ont le droit de demander
          une copie de leurs données à caractère personnel
          faisant l’objet du traitement auprès d’
          {companyName}. Toutefois, en cas de demande de
          copie supplémentaire, {companyName} pourra exiger
          la prise en charge financière de ce coût, par les
          clients et contacts.
          <br />
          <br />
          Si les clients et contacts présentent leur demande
          de copie des données par voie électronique, les
          informations demandées leur seront fournies sous
          une forme électronique d’usage courant, sauf
          demande contraire.
          <br />
          <br />
          Les clients et contacts sont informés que ce droit
          d’accès ne peut porter sur des informations ou
          données confidentielles ou encore pour lesquelles
          la loi n’en autorise pas la communication.
          <br />
          <br />
          Le droit d’accès ne doit pas être exercé de
          manière abusive c’est-à-dire réalisé de manière
          régulière dans le seul but de déstabiliser le
          service concerné.
        </p>
        <span>
          Remarque importante sur le « Consentement »
        </span>
        <p>
          Lorsqu’une personne signe des dossiers Via
          Docusign ou Via notre CRM (Nom, Prénom, Téléphone,
          Email), elle donne explicitement son consentement
          au traitement des données personnelles qu’elle
          aura communiquées au moment de la signature du
          dossier.
          <br />
          <br />
          Toute personne a le droit, à n’importe quel
          moment, de demander le retrait import{" "}
          {formatPhoneNumber} from
          '../../utils/FormatedPhone'; de son consentement
          sur le traitement de ses données personnelles,
          conformément à la réglementation. {companyName}
          s’engage à respecter strictement ce droit dans les
          plus brefs délais.
        </p>
        <span>5. Sous-traitance</span>
        <p>
          {companyName} informe ses clients et contacts
          qu’elle pourra faire intervenir tout sous-traitant
          de son choix dans le cadre du traitement de leurs
          données à caractère personnel.
          <br />
          <br />
          De plus, {companyName} se réserve le droit de
          procéder à un audit auprès de ses sous-traitants
          afin de s’assurer du respect des dispositions du
          RGPD.
        </p>
        <span>6. Sécurité</span>
        <p>
          {companyName} définit et met en œuvre les mesures
          techniques de sécurité, physiques ou logiques,
          qu’il estime appropriées pour lutter contre la
          destruction, la perte, l’altération ou la
          divulgation non autorisée des données de manière
          accidentelle ou illicite.
          <br />
          <br />
          Parmi ces mesures figurent principalement :
          <br />
          • Politique de sécurité des systèmes d’information
          <br />
          • Gestion des identifications et habilitations
          pour l’accès aux données
          <br />
          • Mesures de sauvegarde et archivage
          <br />
          • Mesures de reprises d’activité
          <br />• Audits de sécurité
        </p>
        <span>7. Violation des données</span>
        <p>
          En cas de violation de données à caractère
          personnel, {companyName} s’engage à en notifier à
          la CNIL dans les conditions prescrites par le
          RGPD.
          <br /> <br />
          Si cette violation engendre un risque élevé pour
          les clients et contacts et que les données n’ont
          pas été protégées, {companyName} :
          <br />
          • Avisera les clients et contacts concernés <br />
          • Communiquera aux clients et contacts concernés
          les informations et recommandations nécessaires.
        </p>
        <span>
          8. DPO – Délégué à la Protection des données
        </span>
        <p>
          {companyName} a désigné un délégué à la protection
          des données. Les coordonnées du délégué à la
          protection des données sont les suivantes :
          <br /> <br />
          Pierre AMRAM
          <br /> <br />
          {companyName}
          <br /> <br />
          {companyAddress}
          <br /> <br />
          Email : {companyMail}
          <br /> <br />
          Tel : {companyPhone}
          <br /> <br />
          En cas de nouveau traitement de données à
          caractère personnel, {companyName} saisira
          préalablement le délégué à la protection des
          données.
          <br /> <br />
          Si les clients et contacts souhaitent obtenir une
          information particulière ou souhaitent poser une
          question particulière, il leur sera possible de
          saisir le délégué à la protection des données qui
          leur donnera une réponse dans un délai raisonnable
          au regard de la question posée ou de l’information
          requise.
          <br /> <br />
          En cas de problème rencontré avec le traitement
          des données à caractère personnel, les clients et
          contacts pourront saisir le délégué à la
          protection des données désigné.
        </p>
        <span>
          9. Registre des traitements et Analyse d’Impact
        </span>
        <p>
          {companyName}, en tant que responsable du
          traitement, s’engage à tenir à jour un registre de
          toutes les activités de traitement effectuées.
          <br /> <br />
          Ce registre est un document ou applicatif
          permettant de recenser l’ensemble des traitements
          mis en œuvre par {companyName}, en tant que
          responsable du traitement.
          <br /> <br />
          {companyName} s’engage également à préparer une
          Analyse d’Impact des risques relatifs aux données
          personnelles.
          <br /> <br />
          {companyName} s’engage à fournir à l’autorité de
          contrôle, à première demande, les renseignements
          permettant à ladite autorité de vérifier la
          conformité des traitements à la règlementation
          informatique et libertés en vigueur.
        </p>
        <span>
          10. Droit d’introduire une réclamation auprès de
          la CNIL
        </span>
        <p>
          Les clients et contacts concernés par le
          traitement de leurs données à caractère personnel
          sont informés de leur droit d’introduire une
          plainte auprès d’une autorité de contrôle, à
          savoir la CNIL en France, si celui-ci estime que
          le traitement de données à caractère personnel le
          concernant n’est pas conforme à la règlementation
          européenne de protection des données, à l’adresse
          suivante :
        </p>
        <span>CNIL – Service des plaintes</span>
        <p>
          3 Place de Fontenoy – TSA
          <br /> <br />
          80715 – 75334 PARIS CEDEX 07
        </p>
      </TermsContent>
    </div>
  )
}

export default PrivacyPolicy
