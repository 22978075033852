import React from "react";
import { useSelector } from "react-redux";
import {
  Wrapper,
  Flex,
  Col,
  Contain,
  Container,
  Button,
  H1,
  H3,
} from "../../components/styledComponent/index";
import "./Companies.css";

const Companies = () => {};

export default Companies;
