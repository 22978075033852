import common from "./common";

const facilit = {
  ...common,
  colors: {
    primary: "#21264F", // Bleu foncé
    secondary: "#2176C8", // Bleu ciel
    tertiary: "#E32652", // Rose

    // TITRES
    titlePrimary: "#21264F", // Bleu foncé
    titleSecondary: "#3B5076", // Bleu clair

    // TEXTES
    textPrimary: "#3B5076", // Bleu
    textSecondary: "#21264F", // Bleu foncé
    textTesti: "#000", // Noir

    // BOUTONS
    btnTake: "#2176C8", // Bleu ciel
    btnTakeHover: "#21264F",
    
    btnSearch: "#21264F",
    btnSearchHover: "#333B7D",

    btnPrimary: "#2176C8", // Bleu foncé
    btnPrimaryHover: "#21264F",
    
    btnSecondary: "#E32652", // Rose
    btnSecondaryHover: "#8A004C",

    // ICONS
    iconPrimary: "#32B2F1", // Bleu ciel

    // BACKGROUND
    backColorSky: "#f3fafe",
    backDetails: "#21264F",
    backTesti: "#fff",
    backQualiopi: "#E7EDF6",

    green: "#48E100",
    red: "#E32652",

    light: "#fff",
    dark: "#000",
  },
  fonts:  {
    font: "filson-soft",
  },
  radius: {
    button: "12px",
    search: "26px",
    testi: "50px",
  },
};

export default facilit;
