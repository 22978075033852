import React from "react"
import TermsConditions from "../../components/termsConditions/TermsConditions"

const TermsConditionsScreen = () => {
  return (
    <div>
      <TermsConditions />
    </div>
  )
}

export default TermsConditionsScreen
