import common from "./themes/common";
import styled, { css } from "styled-components";
import Flex from "./Flex";

const Block = styled(Flex)`
  border-radius: 20px;
  padding: 20px;
  margin: 10px;
  flex-direction: column;
  width: 30%;
  border-width: 3px;
  justify-content: space-between;
  align-items: center;

  @media ${common.media.mobile} {
    width: ${(props) => props.widthMobile || common.width.full};
    margin: ${(props) => props.marginMobile || "auto"};
  }
`;

export default Block;
