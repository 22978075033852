import axios from "axios";
let baseUrl;

// FOR DEV ONLY

const url = window.location.href
if (url.includes("localhost")) {
  baseUrl = `https://editinfo--uat.sandbox.my.salesforce-sites.com/facilit/services/apexrest`;
} else {
  baseUrl = `${domainURL}/services/apexrest`; // eslint-disable-line
}

const create = async (lead) => {
  const config = {
    headers: { "Content-Type": "application/json" },
  };

  const response = await axios.post(`${baseUrl}/lead`, lead, config);
  return response.data;
};

const postCandidate = async (candidate) => {
  const config = {
    headers: { "Content-Type": "application/json" },
  };

  const response = await axios.post(`${baseUrl}/email`, candidate, config);
  return response.data;
};

const getQueryData = async (location) => {
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get("extid");
  return paramValue;
};

const getAccount = async (orgId) => {
  const response = await axios.get(`${baseUrl}/account/${orgId}`);
  return response.data;
};

const getContent = async (orgId) => {
  const response = await axios.get(`${baseUrl}/contents/${orgId}`);
  return response.data;
};

const getTrainingContent = async (contentId) => {
  const response = await axios.get(`${baseUrl}/training/${contentId}`);
  return response.data;
};

const getTraining = async (orgId) => {
  const response = await axios.get(`${baseUrl}/trainings/${orgId}`);
  return response.data;
};

const getCategories = async (orgId) => {
  const response = await axios.get(`${baseUrl}/categories`);
  return response.data;
};

const getOrganisedContent = async (orgId) => {
  const response = await axios.get(`${baseUrl}/organised-trainings/${orgId}`);
  return response.data;
};

export default {
  create,
  getQueryData,
  getAccount,
  getTraining,
  getContent,
  getCategories,
  getOrganisedContent,
  getTrainingContent,
  postCandidate,
};
