/* eslint-disable eqeqeq */
import React, { useState } from "react"
import "./Form.css"

// import { useSelector, useDispatch } from "react-redux"
// import { setFilter } from "../../reducers/dataReducer"

const SubCategoriesDropdown = ({
  selectedSubCategories,
  handleSetSubCategory,
  subCategories,
  selectedCategories,
  required,
}) => {
  const [isOpen, setOpen] = useState(false)
  const toggleDropdown = () => setOpen(!isOpen)
  const handleItemClick = (item) => {
    toggleDropdown()
    if (selectedCategories === "Catégorie") {
      handleSetSubCategory(null)
    }
    if (
      selectedSubCategories === item ||
      item === "- Aucune -"
    ) {
      handleSetSubCategory(null)
    } else {
      handleSetSubCategory(item)
    }
  }

  return (
    <div
      className='dropdownSelect'
      onClick={toggleDropdown}>
      <div className='dropdown-header'>
        {selectedSubCategories ? (
          selectedSubCategories
        ) : (
          <span className='default-category'>
            {/* - Aucune - */}
            Sous-catégorie
          </span>
        )}
      </div>
      <div className={`dropdown-body ${isOpen && "open"}`}>
        {subCategories.map((item) => (
          <option
            key={subCategories.indexOf(item)}
            className='dropdown-item'
            onClick={(e) => handleItemClick(item)}
            id={subCategories.indexOf(item)}
            name={item}>
            {item}
          </option>
        ))}
      </div>
    </div>
  )
}

export default SubCategoriesDropdown
