import { createSlice } from '@reduxjs/toolkit'

const cartReducerSlice = createSlice({
  name: 'cartReducer',
  initialState: [],
  reducers: {
    setCart(state, action) {
      state = [...state, action.payload]
      return state
    },
    removeFromCart(state, action) {
      const index = action.payload;
      state.splice(index, 1)
    },
  }
})

export const { setCart, removeFromCart } = cartReducerSlice.actions

export default cartReducerSlice.reducer