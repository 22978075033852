import { useState } from 'react'

export const useField = (type) => {
  const [value, setValue] = useState('')

  const onChange = (event) => {
    setValue(event.target.value)
  }

  return {
    type,
    value,
    onChange
  }
}

export const useCheckbox = (initialState = {}) => {
  const [checkedItems, setCheckedItems] = useState(initialState)

  const handleCheckboxChange = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    })
  }

  const getCheckedValues = () =>
    Object.entries(checkedItems)
      .filter(([name, isChecked]) => isChecked)
      .map(([name]) => name)

  return [checkedItems, handleCheckboxChange, getCheckedValues]
}