import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NotFound from "./screens/404/404";

import i18n from "./utils/i18n";

import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import {
  // BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import {
  Home,
  About,
  Companies,
  Contact,
  Financing,
  Catalog,
  ElearningScreen,
  TermsConditionsScreen,
  LegalNotices,
  PrivacyPolicy,
  Recruiter,
  Cart,
} from "./screens/index";

import "./App.css";

import {
  initialiseAccount,
  initialiseContents,
  initialiseOrganisedContents,
} from "./reducers/dataReducer";
import Payment from "./screens/payment/Payment";
import { setCart } from "./reducers/cartReducer";

function App() {
  const location = useLocation();
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const currentLanguage = i18n.language;

  const url = window.location.href;

  const queryParams = new URLSearchParams(location.search);
  let orgId;

  function setColor(newColor) {
    document.documentElement.style.setProperty(
      "--primary-color",
      newColor.primary
    );
    document.documentElement.style.setProperty(
      "--secondary-color",
      newColor.secondary
    );
    document.documentElement.style.setProperty(
      "--tertiary-color",
      newColor.tertiary
    );
  }

  if (url.includes("localhost")) {
    setColor({
      primary: "#21264f",
      secondary: "#afc4e5",
      tertiary: "#FF6565",
    });
  } else {
    setColor({
      primary: `${color_1}`, // eslint-disable-line
      secondary: `${color_2}`, // eslint-disable-line
      tertiary: `${color_3}`, // eslint-disable-line
    });
  }

  if (url.includes("localhost")) {
    if (queryParams.get("extid")) {
      orgId = queryParams.get("extid");
    } else {
      // FOR DEV ONLY
      orgId = process.env.REACT_APP_ORG_ID;
    }
  } else {
    orgId = extId; // eslint-disable-line
  }

  const localStorage_cart = window.localStorage.getItem("training-cart");

  window.onload = async () => {
    JSON.parse(localStorage_cart)?.map((localStorageItem) =>
      dispatch(setCart(localStorageItem))
    );
  };

  useEffect(() => {
    dispatch(initialiseAccount(orgId));
    dispatch(initialiseOrganisedContents(orgId));
    dispatch(initialiseContents(orgId));
  }, [dispatch, orgId]);

  return (
    <div className="App">
      <Header />
      <main className="App-main">
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="/catalogue" element={<Catalog />} />
          <Route
            path="/catalogue/elearning/:training"
            element={<ElearningScreen />}
          />
          <Route path="/entreprises-pme" element={<Companies />} />
          <Route path="/financement" element={<Financing />} />
          <Route path="/a-propos" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route
            path="/conditions-generales"
            element={<TermsConditionsScreen />}
          />
          <Route path="/mentions-legales" element={<LegalNotices />} />
          <Route
            path="/politique-de-confidentialite"
            element={<PrivacyPolicy />}
          />
          <Route path="/nous-recrutons" element={<Recruiter />} />
          <Route path="*" element={<NotFound />} />

          {/*   <Route path='/panier' element={<Cart />} />
          <Route path='/payment' element={<Payment />} /> */}
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
