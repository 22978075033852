import common from "./themes/common";
import styled, { css } from "styled-components";
import Contain from "./Contain";

const Ul = styled(Contain).attrs(() => ({
  as: "ul",
}))`
  list-style-position: inside;
`;

export default Ul;
