import { arel } from "./arel";
import { skills } from "./skills";
import { facilit } from "./facilit";

const resources = {
  arel: {
    translation: arel,
  },
  facilit: {
    translation: facilit,
  },
  skills: {
    translation: skills,
  },
};

export default resources;
