import { configureStore } from "@reduxjs/toolkit"
import dataReducer from "../reducers/dataReducer"
import cartReducer from "../reducers/cartReducer"

const store = configureStore({
  reducer: {
    data: dataReducer,
    cart: cartReducer,
  },
})

export default store