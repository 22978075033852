import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Space,
  Flex,
  Contain,
  Background,
  Button,
} from "../../components/styledComponent/index";
import "./404.css";

const NotFound = () => {
  /** LANG **/
  const { t, i18n } = useTranslation();

  // LIEN VERS CONTACT PAGE
  const navigate = useNavigate();
  const homepage = () => {
    navigate("/");
  };

  return (
    <>
      <Background id="not-found">
        <Flex
          boxed
          center
          direction="column"
          directionT="column"
          alignHor="center"
          alignVer="center"
        >
          <Contain center large>
            <Trans i18nKey={"not_found_text"} />
          </Contain>
          <Space height="70px" />
          <Button primary onClick={homepage}>
            {t("not_found_btn")}
          </Button>
        </Flex>
      </Background>
    </>
  );
};

export default NotFound;
