import common from "./themes/common";
import styled, { css } from "styled-components";
import Container from "./Container";

const Flex = styled(Container)`
  display: flex;
  justify-content: ${(props) => props.alignHor || "space-between"};
  align-items: ${(props) => props.alignVer || "flex-start"};
  flex-wrap: ${(props) => props.wrap || "nowrap"};

  ${(props) =>
    Object.keys(common.aligns.desktop).reduce((acc, align) => {
      if (props[align]) {
        return acc + `text-align: ${common.aligns.desktop[align]};`;
      }
      return acc;
    }, `text-align: ${props.hebrew ? common.aligns.desktop.right : common.aligns.desktop.left};`)};

  @media ${common.media.desktop} {
    flex-direction: ${(props) => props.direction || "row"};
  }

  @media ${common.media.tablet} {
    flex-direction: ${(props) => props.directionT || "row"};
  }

  @media ${common.media.mobile} {
    flex-direction: ${(props) => props.directionM || "column"};
    padding: ${(props) => props.paddingM || "0"};
    justify-content: ${(props) => props.alignHorM || "center"};
    align-items: ${(props) => props.alignVerM || "center"};
  }
`;

export default Flex;
