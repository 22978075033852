import { useEffect } from "react"
import { useLocation } from "react-router-dom"

const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation()

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    }

    scrollToTop()
  }, [pathname])

  return children
}

export default ScrollToTop
