import styled from "styled-components"

const HeaderContainer = styled.div`
  height: 306px;
  width: 100%;
  background-size: cover !important;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.7) 0%,
      rgba(255, 255, 255, 0) 54.07%
    ),
    url(${props => props.illustration}); 
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  place-content: end;
  margin-top: -105px;

  @media screen and (max-width: 768px) {
    height: 240px;
    margin-top: -70px;
  }

  @media screen and (max-width: 480px) {
    height: 200px;
    margin-top: -90px;
  }
`

export { HeaderContainer }
