import React, { useState, useEffect } from "react";
import { Container, Contain, Button } from "../../styledComponent/index";
import { FaFilter, FaTimes } from "react-icons/fa";
import "./Filters.css";

import { useSelector, useDispatch } from "react-redux";
import { setFilter } from "../../../reducers/dataReducer";

const Filters = ({ filterBy, sortBy, onFilterBy, onSortBy, formations }) => {
  ///////////////////////// en plus
  const selectedItem = useSelector((state) => state.data.filter);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setFilter(null)); // Réinitialisez l'élément sélectionné à null lorsque le composant est monté
  }, [dispatch]);

  let categories = Object.keys(useSelector((state) => state.data.trainings));
  categories.sort((a, b) =>
    a.localeCompare(b, "fr", { ignorePunctuation: true })
  );
  categories.unshift("Toutes les catégories");

  const [activeLabel, setActiveLabel] = useState(null); // Gérez l'état de la classe active pour le label

  const handleItemClick = (item) => {
    if (selectedItem === item || item === "Toutes les catégories") {
      dispatch(setFilter(null));
    } else {
      dispatch(setFilter(item));
    }
    setActiveLabel(item); // Définissez le label actif lorsqu'il est cliqué
  };

  /////////////////end

  const [showFilterBy, setShowFilterBy] = useState(true);
  const [showFormationPresentielFilter, setShowFormationPresentielFilter] =
    useState(false);

  const toggleFilterBy = () => {
    setShowFilterBy(!showFilterBy);
  };

  useEffect(() => {
    // Définit la valeur par défaut sur "Popularité" si filterBy est null ou undefined
    if (!filterBy) {
      onFilterBy("Popularité");
    }

    // Vérifie si une formation filtrée est présentielle
    if (formations && formations.length > 0) {
      const hasPresentielFormation = formations.some(
        (formation) => formation.type === "Formation présentiel"
      );
      // Affiche le filtre "Formation présentiel" si une formation présentielle est filtrée, sinon le cache
      setShowFormationPresentielFilter(hasPresentielFormation);
    } else {
      setShowFormationPresentielFilter(false);
    }
  }, [filterBy, onFilterBy, formations]);

  // Handle the user changing the sorting option
  const handleFilterChange = (e) => {
    const value = e.target.value;
    onFilterBy(value);
  };

  // Handle the user changing the "show only" option
  const handleSortChange = (e) => {
    const value = e.target.value;
    onSortBy(value);
  };

  // Render the component's UI
  return (
    <>
      {showFilterBy && (
        <Container RightM paddingMobile="0" className="filters-container">
          <Contain>
            Filtrer par thème
          </Contain>

          <Container radius="12px" className="filter-theme" padding="15px">
            {categories.map((item) => (
              <div
                key={categories.indexOf(item)}
                onClick={(e) => handleItemClick(item)}
                id={categories.indexOf(item)}
              >
                <input type="radio" name="trainings" id="filter-radio[]" />
                <label
                  htmlFor={`filter-radio-${categories.indexOf(item)}`}
                  className={activeLabel === item ? "active" : ""}
                >
                  {item}
                </label>
              </div>
            ))}
          </Container>

          <Contain>
            Trier par
          </Contain>

          <Contain leftM className="filter-by">
            <label>
              <input
                type="radio"
                name="filterBy"
                value="Popularité"
                checked={filterBy === "Popularité"}
                onChange={handleFilterChange}
              />
              Popularité
            </label>
            <label>
              <input
                type="radio"
                name="filterBy"
                value="Ordre alphabétique"
                checked={filterBy === "Ordre alphabétique"}
                onChange={handleFilterChange}
              />
              Ordre alphabétique
            </label>
          </Contain>

          {/* Section for choosing the "show only" option */}
          {showFormationPresentielFilter && (
            <Contain leftM className="sort-by">
              <h6>Afficher seulement</h6>
              <label>
                <input
                  type="radio"
                  name="sortBy"
                  value="Formation distanciel"
                  checked={sortBy === "Formation distanciel"}
                  onChange={handleSortChange}
                />
                Formation distanciel
              </label>
              <label>
                <input
                  type="radio"
                  name="sortBy"
                  value="Formation présentiel"
                  checked={sortBy === "Formation présentiel"}
                  onChange={handleSortChange}
                />
                Formation présentiel
              </label>
            </Contain>
          )}
        </Container>
      )}
      {!showFilterBy && (
        <Container rightM paddingMobile="0">
          <Button
            secondary
            padding="10px 13px"
            radius="40px 0 0 40px"
            onClick={toggleFilterBy}
            margin="0"
          >
            <FaFilter />
          </Button>
        </Container>
      )}
    </>
  );
};

export default Filters;
