import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FaTimes, FaBars } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Contain } from "../styledComponent/index";

import MediaQuery from "react-responsive";

function Menu() {
  // ORGANISMES
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const currentOrganisme = i18n.language;

  const [isOpen, setIsOpen] = useState(false);
  const [isMenuSticky, setIsMenuSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      // Ajustez la valeur "200" selon vos besoins pour déterminer à quel point le défilement doit être effectué avant que le menu devienne blanc.
      setIsMenuSticky(scrollTop > 150);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const companyData = useSelector((state) => state.data.company);

  const toggleMenu = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };
  const closeMenu = () => {
    setIsOpen(false);
  };

  const myNav = [
    { title: t("home_menu"), link: "/" },
    { title: t("our_catalog_menu"), link: "/catalogue" },
    { title: t("about_us_menu"), link: "/a-propos" },
    { title: t("contact_menu"), link: "/contact" },
    { title: t("formateur_menu"), link: "/nous-recrutons" },
  ];

  if (companyData?.type === "Corporate") {
    myNav.splice(2, 0, {
      title: "Entreprises",
      link: "/entreprises-pme",
    });
    myNav.splice(5, 0, {
      title: "Nous recrutons",
      link: "/nous-recrutons",
    });
  }

  const myNavMap = () => (
    <ul className="menu__items">
      {myNav.map(({ title, link }, index) => (
        <li key={index} className="menu__item">
          <Contain normal primary paddingMobile="5px">
            <NavLink
              to={link}
              onClick={closeMenu}
              className={location.pathname === link ? "selected" : ""}
            >
              {title}
            </NavLink>
          </Contain>
        </li>
      ))}
    </ul>
  );

  return (
    <nav>
      <MediaQuery maxWidth={900}>
        <div className="menu__header">
          <button
            onClick={toggleMenu}
            className={`menu__toggle ${isOpen ? "open" : ""}`}
          >
            {isOpen ? (
              <>
                <FaTimes />
              </>
            ) : (
              <>
                <FaBars />
              </>
            )}
          </button>
        </div>
        <div className={`menu ${isOpen ? "open" : ""}`}>
          <Contain paddingMobile="20px 60px" marginT="40px">
            <img src={companyData?.logo} alt="logo" />
          </Contain>
          {myNavMap()}
        </div>
      </MediaQuery>
      <MediaQuery minWidth={1024}>{myNavMap()}</MediaQuery>
    </nav>
  );
}

export default Menu;
