import React, { useState } from "react";
import { useSelector } from "react-redux";
import RecruiterForm from "./RecruiterForm";
import {
  Container,
  Flex,
  Contain,
  H1,
  H3,
} from "../../components/styledComponent/index";
import "./Recruiter.css";

function Recruiter() {
  const [submitted, setSubmitted] = useState(false);
  const contentReducer = useSelector((state) => state.data.website_content);

  const backRecruteur =
    contentReducer?.filter((item) => item.content_id === "recruteurHeader") ||
    [];

  return (
    <div id="recruiter">
      <Flex
        height="55vh"
        heightMobile="400px"
        width="100%"
        backgroundURL={backRecruteur?.[0]?.value || ""}
        direction="column"
        alignHor="flex-end"
        alignVer="center"
        padding="0 0 50px"
        alignHorMobile="flex-end"
      >
        <H1 Light Center largeM>
          Nous recrutons
        </H1>
        <Contain Light Center medium>
          Vous êtes formateur et vous cherchez du travail ?
          <br /> Facilit Formation s’engage à vous mettre en relations avec nos
          equipes et vous trouver du travail
        </Contain>
      </Flex>

      <Container CenterM Center margin="50px auto">
        <H3 Primary CenterM>
          REMPLISSEZ LE FORMULAIRE
        </H3>
        <Contain textAlign="center" Primary CenterM marginB="26px">
          Un membre de notre équipe reviendra vers vous afin d’etudier votre
          candidature
        </Contain>

        {submitted ? (
          <p className="success-message" style={{ color: "black" }}>
            Merci d'avoir soumis le formulaire ! Nous vous contacterons bientôt.
          </p>
        ) : (
          <RecruiterForm setSubmitted={setSubmitted} />
        )}
      </Container>
    </div>
  );
}

export default Recruiter;
