import common from "./common";

const skills = {
  ...common,
  colors: {
    primary: "#522C5F", // Violet
    secondary: "#1E4B3D", // Vert

    // TITRES
    titlePrimary: "#1E4B3D",
    titleSecondary: "#5F8075",

    // TEXTES
    textPrimary: "#3B5076", // Bleu
    textSecondary: "#21264F", // Bleu foncé
    textTesti: "#fff", // Blanc

    // BOUTONS
    btnTake: "#522C5F", // Violet
    btnTakeHover: "#3E2147",
    
    btnSearch: "#1E4B3D",
    
    btnPrimary: "#522C5F", // Violet
    btnPrimaryHover: "#3E2147",
    
    btnSecondary: "#1E4B3D", // Vert
    btnSecondaryHover: "#31745F",

    // ICONS
    iconPrimary: "#fff", // Blanc
    
    // BACKGROUND
    backColorSky: "#E7F6EE",
    backDetails: "#1E4B3D",
    backTesti: "#1E4B3D",
    backQualiopi: "#EAF8F1",

    green: "#48E100",
    red: "#E32652",

    light: "#fff",
    dark: "#000",
  },
  fonts:  {
    font: "filson-soft",
  },
  radius: {
    button: "12px",
    search: "26px",
    testi: "50px",
  },
};

export default skills;
