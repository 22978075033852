import styled from "styled-components";

const Span = styled.span`
  ${(props) =>
    Object.keys(props.theme.colors).reduce((acc, color) => {
      if (props[color]) {
        return acc + `color: ${props.theme.colors[color]};`;
      }
      return acc;
    }, `color: ${props.theme.colors.titleSecondary};`)};
`;

export default Span;
