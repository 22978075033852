import React from "react";
import { Container, H1 } from "../../components/styledComponent/index";

const Financement = () => (
  <div id="financement">
    <Container>
      <H1>Financement</H1>
    </Container>

    <Container>
      <p>
        En France il existe différents dispositifs vous permettant de financer
        votre formation.
      </p>

      <h3>Le plan de formation en entreprise :</h3>
      <p>
        Vous êtes salarié ou chef d’entreprise, bénéficier d’une formation prise
        en charge par votre OPCO pour vos employés ou votre entreprise.
      </p>
      <button className="linkOpco">En savoir plus</button>
      <h3>Les abondemments Pôle Emploi et Région :</h3>
      <p>
        Dans le cas où vous ne correspondait à aucune des catégories
        précèdentes, un dispositif supplementaire peut-être mis en place afin de
        vous aider à vous former.
      </p>
    </Container>

    <Container>
      <h2>Plan de financements de formations pour entreprise et PME</h2>
      <p>
        Vous êtes à la tête d’une petite ou grande entreprise ? Chaque année
        vous cotisez auprès d’un OPCO, ce qui vous donne droit à un budget
        formation pour vous et vos salariés
      </p>
    </Container>

    <Container>
      <h2>LES OPÉRATEURS DE COMPÉTENCES</h2>
      <p>
        Depuis le 1er avril 2019, onze opérateurs de compétences (OCPO), chargés
        d’accompagner la formation professionnelle, ont été agréés. Ils
        remplacent les anciens organismes paritaires collecteurs agréées (OPCA).
        Ces opérateurs de compétences ont pour missions de financer
        l’apprentissage, d’aider les branches à construire les certifications
        professionnelles et d’accompagner les PME pour définir leurs besoins en
        formation.
      </p>
    </Container>

    <Container>
      <ul>
        <li>
          <h4>Une couverture nationale</h4>
          Les formateurs de Facilit Formation se déplacent dans toute la France
          pour vous proposer des formations de qualité.
        </li>
        <li>
          <h4>Accompagnement administratif</h4>
          Votre conseiller formation vous accompagne tout au long de votre
          projet et vous facilite toutes les démarches administratives.
        </li>
        <li>
          <h4>Des formateurs expérimentés</h4>
          Les formateurs de Facilit Formation sont réputés pour leur pédagogie
          et leur expérience. Ils vous forment de manière professionnelle.
        </li>
        <li>
          <h4>Une totale flexibilité</h4>
          Vous décidez de tout ! Choisissez les dates et le lieu de votre
          formation et nous nous adaptons à vos besoins.
        </li>
        <li>
          <h4>Aucune avance de frais</h4>
          Facilit Formation vous propose des formations entièrement financées
          par les OPCO.
        </li>
        <li>
          <h4>Des formations chez vous</h4>
          Plus besoin de se déplacer pour être formé de manière qualitative. Les
          formateurs se déplacent chez vous directement.
        </li>
      </ul>
    </Container>

    <Container>
      <h3>Je choisis ma formation</h3>
      <p>
        Facilit Formation fait appel aux meilleurs experts pour élaborer et
        dispenser des formations de qualité. Vous avez le choix entre des
        centaines de formations dans différents domaines.
      </p>

      <h3>Le formateur réalise un audit</h3>
      <p>
        Avant chaque formation, nous réalisons un audit téléphonique afin de
        connaitre votre métier et d’adapter le contenu de votre formation aux
        besoins des stagiaires. Le plan de cours sera le reflet de ce que vous
        souhaiter aborder lors de votre formation.
      </p>

      <h3>Le formateur se déplace chez vous</h3>
      <p>
        Une fois le plan de cours validé par vos soins, nos formateurs se
        déplacent chez vous à la date et l’endroit qui vous conviennent pour la
        durée de la formation.
      </p>
      <button>Trouver ma formation</button>
    </Container>
  </div>
);

export default Financement;
