import React from "react"
import { useSelector } from "react-redux"


import { TermsHeader, TermsContent } from "./TermsStyled"

const LegalNotices = () => {
  // Data Salesforce
  const companyDatas = useSelector(
    (state) => state.data.company
  )
  const companyName = companyDatas?.company
  const companySiret = companyDatas?.siret
  const companyAdress = `${companyDatas?.address?.street}, ${companyDatas?.address?.postalCode}, ${companyDatas?.address?.city}, ${companyDatas?.address?.country}.`

  const imageLegal =
    useSelector((state) =>
      state.data.website_content?.filter(
        (item) => item.content_id === "legal"
      )
    ) || []

  return (
    <div>
      <TermsHeader image={imageLegal[0]?.value}>
        <h4>Mentions Légales</h4>
      </TermsHeader>
      <TermsContent>
        <p>
          Merci de lire avec attention les différentes
          modalités d’utilisation du site {companyName}{" "}
          avant d’y parcourir ses pages. En vous connectant
          sur ce site, vous acceptez sans réserve les
          présentes modalités.
          <br />
          <br />
          Conformément à l’article n°6 de la Loi n°2004-575
          du 21 Juin 2004 pour la confiance dans l’économie
          numérique, les responsables du présent site
          internet sont :
        </p>
        <h6>Editeur du Site :</h6>
        <p>
          {companyName}
          <br />
          {/* S.A.S au capital de 7500€ enregistrée au RCS de Paris sous le numéro 448246892. <br/>*/}
          {companySiret &&
            `Numéro de SIRET : ${companySiret}`}
          <br />
          {companyAdress &&
            `Siège social: ${companyAdress}`}
        </p>
        <h6>Hébergement :</h6>
        <p>
          {" "}
          Hébergeur : Salesforce.com FRANCE <br />
          PARIS (75007) <br />
          Site Web : www.salesforce.com
        </p>
        <h6>Développement :</h6>
        <p>
          {companyName}
          <br />
          <br />
          {/* S.A.S au capital de 7500€ enregistrée au RCS de Paris sous le numéro 448246892. <br/>*/}
          {companySiret &&
            `Numéro de SIRET : ${companySiret}`}
          <br />
          {companyAdress &&
            `Siège social: ${companyAdress}`}
        </p>
        <h6>Conditions d'utilisation</h6>
        <p>
          Pour un meilleur confort d’utilisation et une
          visualisation plus agréable des contenus, nous
          vous recommandons de parcourir ce site via les
          navigateurs web les plus récents et mis à jour.
        </p>
        <h6>Prérequis techniques</h6>
        <p>
          Pour accéder à nos formations vous aurez besoin
          d’une connexion internet stable et d’un
          ordinateur, tablette, ou smartphone.
        </p>
        <h6>Accéssibilité</h6>
        <p>
          Nos formations ne sont pas accessibles à toutes
          personnes en situation de handicap.
          <br />
          <br />
          {companyName} fait tout son possible pour afficher
          des informations fiables sur son site internet.
          Toutefois, des erreurs ou omissions peuvent
          survenir.
        </p>
        <h6>Cookies</h6>
        <p>
          Le site peut être amené à vous demander
          l’acceptation des cookies pour des besoins de
          statistiques et d’affichage. Certaines parties de
          ce site ne peuvent être fonctionnelles sans
          l’acceptation de cookies. Pour en savoir plus,
          vous pouvez visiter notre page dédiée à la
          politique de confidentialité ici.
        </p>
        <h6>Emails</h6>
        <p>
          {" "}
          Le site internet peut vous demander des
          informations personnelles comme votre adresse
          e-mail et numéro de téléphone. Ces informations
          peivent être utilisées pour vous contacter par
          e-mail ou numéro de téléphone.
        </p>
        <h6>Limitation contractuelles sur les données</h6>
        <p>
          Les informations contenues sur ce site sont aussi
          précises que possible et le site remis à jour à
          différentes périodes de l’année, mais peut
          toutefois contenir des inexactitudes ou des
          omissions.
          <br />
          <br />
          Tout contenu téléchargé se fait aux risques et
          périls de l’utilisateur et sous sa seule
          responsabilité. En conséquence, ne saurait être
          tenu responsable d’un quelconque dommage subi par
          l’ordinateur de l’utilisateur ou d’une quelconque
          perte de données consécutives au téléchargement.
          De plus, l’utilisateur du site s’engage à accéder
          au site en utilisant un matériel récent, ne
          contenant pas de virus et avec un navigateur de
          dernière génération mis-à-jour.
        </p>
        <h6>Propriété intellectuelle</h6>
        <p>
          Tout le contenu du présent site incluant, de façon
          non limitative, les graphismes, images, textes,
          vidéos, animations, sons, logos, gifs et icônes
          ainsi que leur mise en forme sont la propriété
          exclusive de la société à l’exception des marques,
          logos ou contenus appartenant à d’autres sociétés
          partenaires ou auteurs.
          <br />
          <br />
          Toute reproduction, distribution, modification,
          adaptation, retransmission ou publication, même
          partielle, de ces différents éléments est
          strictement interdite sans l’accord exprès par
          écrit de la société {companyName}. Cette
          représentation ou reproduction, par quelque
          procédé que ce soit, constitue une contrefaçon
          sanctionnée par les articles L.335-2 et suivants
          du Code de la propriété intellectuelle. Le
          non-respect de cette interdiction constitue une
          contrefaçon pouvant engager la responsabilité
          civile et pénale du contrefacteur. En outre, les
          propriétaires des Contenus copiés pourraient
          intenter une action en justice à votre encontre.
        </p>
        <h6>Litiges</h6>
        <p>
          Les présentes conditions du site sont régies par
          les lois françaises et toute contestation ou
          litiges qui pourraient naître de l’interprétation
          ou de l’exécution de celles-ci seront de la
          compétence exclusive des tribunaux dont dépend le
          siège social de la société. La langue de
          référence, pour le règlement de contentieux
          éventuels, est le français.
        </p>
      </TermsContent>
    </div>
  )
}

export default LegalNotices
