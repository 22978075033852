import common from "./themes/common";
import styled, { css } from "styled-components";

const Col = styled.div`
  width: ${common.width.full};
  height: ${(props) => props.height || "auto"};
  margin: ${(props) => props.margin || "0"};
  padding: ${(props) => props.padding || "0"};
  order: ${(props) => props.order || "inherit"};

  ${(props) => {
    const colCount = props.twoCol
      ? 2
      : props.threeCol
      ? 3
      : props.fourCol
      ? 4
      : props.fiveCol
      ? 5
      : props.sixCol
      ? 6
      : 1;
    const width = `${
      (100 - (colCount + 5) * parseFloat(props.margin || "1%")) / colCount
    }%`;
    return css`
      width: ${(props) => props.width || width};
      margin: ${props.margin || "1%"};
    `;
  }};

  ${(props) =>
    Object.keys(props.theme.colors).map(
      (color) =>
        props[color] &&
        css`
          background: ${props.theme.colors[color]};
        `
    )};

  ${(props) =>
    Object.keys(props.theme.radius).reduce((acc, radiu) => {
      if (props[radiu]) {
        return acc + `border-radius: ${props.theme.radius[radiu]};`;
      }
      return acc;
    }, `border-radius: 12px;`)};

  ${(props) =>
    Object.keys(common.shadows).reduce((acc, shadow) => {
      if (props[shadow]) {
        return acc + `box-shadow: ${common.shadows[shadow]};`;
      }
      return acc;
    }, ``)};
  ${(props) =>
    Object.keys(common.aligns.desktop).map(
      (align) =>
        props[align] &&
        css`
          text-align: ${common.aligns.desktop[align]};
        `
    )};
  @media ${common.media.mobile} {
    ${(props) =>
      (props.twoCol ||
        props.threeCol ||
        props.fourCol ||
        props.fiveCol ||
        props.sixCol ||
        "null") &&
      css`
        width: ${(props) => props.widthMobile || common.width.full};
        margin: ${props.margin || "0"};
        padding: ${(props) => props.paddingMobile || "10px"};
      `}
    ${(props) =>
      Object.keys(common.aligns.mobile).map(
        (align) =>
          props[align] &&
          css`
            text-align: ${common.aligns.mobile[align]};
          `
      )}
    order: ${(props) => props.orderM || "inherit"};
  }
`;

export default Col;
