import styled from "styled-components"

const ScreenLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: -25px 0 25px 130px;
  /* z-index: 0 !important; */

  @media screen and (max-width: 768px) {
    margin: 0;
    /* justify-content: center; */
    & > *:not(:last-child) {
      display: none;
    }
  }
`
const StickyHeader = styled.div`
  position: sticky;
  top: 153px;

  @media screen and (max-width: 768px) {
    top: 140px;
  }
`

export { ScreenLinks, StickyHeader }
