import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { formatPhoneNumber } from "../../utils/FormatedPhone";
import Form from "../../components/form/Form";
import {
  Container,
  Background,
  Flex,
  Col,
  Contain,
  H1,
  H3,
  Space,
} from "../../components/styledComponent/index";

import "./Contact.css";

const Contact = () => {
  // ORGANISMES
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const currentOrganisme = i18n.language;
  const colorIco = `where-ico-${currentOrganisme}`;

  // const { category, subcategory } = location.state
  const { category = null, subcategory = null } = location.state || {};

  console.log("truc", category, subcategory);

  // Data Salesforce
  const companyData = useSelector((state) => state.data.company);
  const { street, postalCode, city } = companyData?.address || {};
  const contentReducer = useSelector((state) => state.data.website_content);
  const backContact =
    contentReducer &&
    contentReducer.filter((item) => item.content_id === "contact");

  // Data
  const days = ["Monday"];
  const { business_hours = {} } = companyData || {};

  const businessHours = [
    {
      day: "Lundi au vendredi",
      hours: "",
    },
    {
      day: "Samedi & Dimanche",
      hours: "Fermé",
    },
    {
      day: "Jours fériés",
      hours: "Fermé",
    },
  ];

  for (const [i, day] of days.entries()) {
    const {
      [`${day}StartTime`]: startTime = "",
      [`${day}EndTime`]: endTime = "",
    } = business_hours;
    const hours =
      startTime.substring(0, startTime.length - 8) +
      " - " +
      endTime.substring(0, endTime.length - 8);
    businessHours[0].hours += hours;
    if (i < days.length - 1) {
      businessHours[0].hours += ", ";
    }
  }

  const businessHoursMap = () => (
    <nav>
      {businessHours.map(({ day, hours }, index) => (
        <Contain key={index} Primary margin="0">
          {day}: {hours}
        </Contain>
      ))}
    </nav>
  );

  const myDetails = [
    {
      title: "PAR TELEPHONE",
      contain: (
        <a
          href={`tel:${formatPhoneNumber(companyData?.phone)}`}
          target="_blank"
          rel="noreferrer"
        >
          {formatPhoneNumber(companyData?.phone)}
        </a>
      ),
    },
    {
      title: "PAR EMAIL",
      contain: (
        <a
          href={`mailto:${companyData?.email_info}?subject=Demande de formation`}
          target="_blank"
          rel="noreferrer"
        >
          {companyData?.email_info}
        </a>
      ),
    },
    {
      title: "ADRESSE",
      contain: (
        <a
          href="https://www.google.com/maps/place/161+Av.+de+Verdun,+94200+Ivry-sur-Seine,+France/@48.8074625,2.3728493,17z/data=!3m1!4b1!4m6!3m5!1s0x47e673cfcc4c4e65:0x1f48d122b6f3c5f!8m2!3d48.807459!4d2.375038!16s%2Fg%2F11c5jjzbdx"
          target="_blank"
          rel="noreferrer"
        >
          {street}, {postalCode} {city}
        </a>
      ),
      //   contain: `${street}, ${postalCode} ${city}`,
    },
    {
      title: "",
      contain: `En tant qu'organisme proposant des formations à distance, nous ne recevons pas de public dans nos locaux. En cas de difficultés ou en situation d’handicap, n’hésitez pas à nous contacter. Nous sommes à votre disposition.`,
    },
    {
      title: `HORAIRES D'OUVERTURE`,
      contain: businessHoursMap(),
    },
  ];



  // State to keep track of form input values
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    training: "",
    situation: "",
    message: "",
    error: "",
  });

  const { name, email, phone, message, error } = formData;

  const [submitted, setSubmitted] = useState(false);



  return (
    <div id="contact">
      
    </div>
  );
};

export default Contact;
