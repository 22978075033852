import React from "react";
import { Link } from "react-router-dom";
import { Contain, Col, Background } from "../../styledComponent/index";

const TrainingCard = ({ training }) => {
  const path = "/catalogue/elearning/";

  return (
    // MAP DE FORMATION
    <Col fiveCol key={training.id} light className="training">
      <Link to={`${path}${training.name}`} state={{ training }}>
        <Background img={`url(${training.image})`} height="120px" />
        <Contain
          title={training.name}
          center
          padding="10px 0"
          className="title-training"
        >
          {training.name}
        </Contain>
      </Link>
    </Col>
  );
};

export default TrainingCard;
