import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import {
  H1,
  Flex,
  Container,
  Contain,
  H3,
  Button,
  Block,
} from "../../components/styledComponent/index";

const Funding = ({ gotTraining }) => {
  const location = useLocation();
  const { category, subcategory } = gotTraining;

  const contentReducer =
    useSelector((state) => state.data.website_content) || [];
  const images = contentReducer.filter(
    (item) =>
      item.content_id === "cpf" ||
      item.content_id === "pole-emploi" ||
      item.content_id === "constructys" ||
      item.content_id === "ico-entreprise-1"
  );

  const imageOject = Object.fromEntries(
    images.map(({ content_id, value }) => [content_id, value])
  );

  const { training } = location?.state;
  const funding = training.funding ? training.funding.split(";") : [];

  const formationParticulier = funding.includes("Particulier");
  const formationCPF = funding.includes("CPF");
  const formationPoleEmploi = funding.includes("Pole Emploi");
  const formationOPCO = funding.includes("OPCO");
  const formationConstructys = funding.includes("OPCO Constructys");

  return formationParticulier ||
    formationCPF ||
    formationPoleEmploi ||
    formationOPCO ||
    formationConstructys ? (
    <Container Secondary width="100%" padding="80px 0">
      <Container>
        <H1 Initial Center Primary margin="0 0 50px">
          Comment financer votre formation ?
        </H1>
        <Flex Center alignVer="stretch" wrap="wrap">
          {formationParticulier && (
            <Block Light paddingMobile="30px">
              <H3 margin="45px 0 0 0" Initial Primary size="10px">
                Vous êtes un particulier ?
              </H3>
              <Contain padding="0 50px">
                <img
                  src={imageOject["pole-emploi"]}
                  alt="pole-emploi"
                  width="50%"
                />
              </Contain>
              <Contain>
                Faites financer votre formation par un OPCO l’operateur de
                competence de votre entreprise
              </Contain>
              <Button tertiary>
                {" "}
                <Link
                  to="/a-propos"
                  state={{
                    category: category,
                    subcategory: subcategory,
                  }}
                >
                  Je découvre
                </Link>
              </Button>
            </Block>
          )}
          {formationCPF && (
            <Block Light paddingMobile="30px">
              <H3 margin="45px 0 0 0" Initial Primary>
                Vous êtes un particulier ?
              </H3>
              <Contain padding="0 50px">
                <img src={imageOject["cpf"]} alt="cpf" />
              </Contain>
              <Contain>
                Vous avez êtes/ avez été actif dans le monde du travail pendant
                au moins 12 mois, vous cumuler un montant destiné à la formation
                dans le cadre de votre compte professionnel de formation (CPF).
              </Contain>
              <Button tertiary>
                {" "}
                <Link
                  to="/a-propos"
                  state={{
                    category: category,
                    subcategory: subcategory,
                  }}
                >
                  Je découvre
                </Link>
              </Button>
            </Block>
          )}
          {formationPoleEmploi && (
            <Block Light paddingMobile="30px">
              <H3 margin="45px 0 0 0" Primary Initial>
                Vous êtes une entreprise ?
              </H3>
              <Contain padding="0 50px">
                <img src={imageOject["constructys"]} alt="constructys" />
              </Contain>
              <Contain>
                Faites financer votre formation par un OPCO l’operateur de
                competence de votre entreprise
              </Contain>
              <Button tertiary>
                {" "}
                <Link
                  to="/a-propos"
                  state={{
                    category: category,
                    subcategory: subcategory,
                  }}
                >
                  Je découvre
                </Link>
              </Button>
            </Block>
          )}
          {formationOPCO && (
            <Block Light paddingMobile="30px">
              <H3 Primary>OPCO</H3>
              <Contain padding="0 50px">
                <img src={imageOject["constructys"]} alt="constructys" />
              </Contain>
              <Contain>
                Faites financer votre formation par un OPCO l’operateur de
                competence de votre entreprise
              </Contain>
              <Button tertiary>
                {" "}
                <Link
                  to="/a-propos"
                  state={{
                    category: category,
                    subcategory: subcategory,
                  }}
                >
                  Je découvre
                </Link>
              </Button>
            </Block>
          )}
          {formationConstructys && (
            <Block Light paddingMobile="30px">
              <H3 Primary>OPCO CONSTRUCTYS</H3>
              <Contain padding="0 50px">
                <img src={imageOject["ico-entreprise-1"]} alt="opco" />
              </Contain>
              <Contain>
                Faites financer votre formation par un OPCO l’operateur de
                competence de votre entreprise
              </Contain>
              <Button tertiary>
                {" "}
                <Link
                  to="/a-propos"
                  state={{
                    category: category,
                    subcategory: subcategory,
                  }}
                >
                  Je découvre
                </Link>
              </Button>
            </Block>
          )}
        </Flex>
      </Container>
    </Container>
  ) : (
    <Container>
      <hr />
    </Container>
  );
};

export default Funding;
