import common from "./themes/common";
import styled, { css } from "styled-components";

const Dot = styled.span`
  width: 8px;
  height: 8px;
  border-radius: 50%;

  background-color: ${(props) =>
    props.active ? common.primary : "#E0ECE9"};

    ${(props) =>
      Object.keys(props.theme.colors).reduce((acc, color) => {
        if (props[color]) {
          return acc + `background-color: ${props.theme.colors[color]};`;
        }
        return acc;
      }, `background-color: #E0ECE9;`)}; 
  


  margin-left: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
`;

export default Dot;
